/**
 * This is the first section of "house damage"
 */

const damageCauseTranslations = {
  en: {
    question: 'What kind of accident was it?',
    questionDescription: 'Please select the option which describes best what happened',
    options: {
      theft: 'Damages due to theft',
      water: 'Damages due to water pipes',
      nature: 'Damages due to natural hazards',
      fire: 'Damages due to fire',
      vandalism: 'Damages due to vandalism',
      windows: 'Windows damages'
    }
  },
  de: {
    question: 'Welche Art Schaden ist entstanden?',
    questionDescription: 'Bitte wählen Sie die Option, die ab ehesten zutrifft.',
    options: {
      theft: 'Diebstahlschaden',
      water: 'Wasserschaden',
      nature: 'Schaden durch höhere Gewalt',
      fire: 'Feuerschaden',
      vandalism: 'Schaden durch Vandalismus',
      windows: 'Schaden am Fenster'
    }
  }
};

export default damageCauseTranslations;
