import Api from './api';

export const SurveyActions = {
  SET_FEAR_OF_DAMAGE: 'set_fear_of_damage',
  SET_HAS_APARTMENT: 'set_has_apartment',
  SET_HAS_HOUSE: 'set_has_house',
  SET_LIVIN_RENTED: 'set_livin_rented',
  SET_LIVIN_OWNED: 'set_livin_owned',
  SET_CONNECT_DATA_ID: 'set_connect_data_id',

  SET_FORM_RESET: 'set_form_reset',
  SET_FORM_SUBMITING: 'set_form_submiting',
  SET_FORM_SUBMITED: 'set_form_submited',
  SET_FORM_ERROR: 'set_form_error'
};

// SET FEAR OF DAMAGE
export function setFearOfDamage(itemId) {
  return (dispatch) => dispatch({ type: SurveyActions.SET_FEAR_OF_DAMAGE, payload: itemId });
}

// SET HAS APARTMENT
export function setHasApartment() {
  return (dispatch) => dispatch({ type: SurveyActions.SET_HAS_APARTMENT });
}

// SET HAS APARTMENT
export function setHasHouse() {
  return (dispatch) => dispatch({ type: SurveyActions.SET_HAS_HOUSE });
}

// SET LIVING RENTED
export function setLivingRented() {
  return (dispatch) => dispatch({ type: SurveyActions.SET_LIVIN_RENTED });
}

// SET LIVING OWNED
export function setLivingOwned() {
  return (dispatch) => dispatch({ type: SurveyActions.SET_LIVIN_OWNED });
}

// SET CONNECT DATA ID - USED TO CONNECT SURVEY TO CLAIM/EXISTING CLAIM/CALLBACK
export function setConnectDataId(connectDataId, connectedDataText) {
  return (dispatch) => dispatch({ type: SurveyActions.SET_CONNECT_DATA_ID, connectDataId, connectedDataText });
}

// SET FORM RESET
export function setFormReset() {
  return (dispatch) => dispatch({ type: SurveyActions.SET_FORM_RESET });
}

// FORM SUBMIT
export function submitSurvey(data) {
  return (dispatch) => {
    dispatch({ type: SurveyActions.SET_FORM_SUBMITING, formSubmiting: true });

    new Api()
      .submitSurvey(data)
      .then((response) => {
        dispatch({ type: SurveyActions.SET_FORM_SUBMITING, formSubmiting: false });
        dispatch({
          type: SurveyActions.SET_FORM_SUBMITED,
          formResponse: response.data
        });
      })
      .catch((err) => {
        dispatch({ type: SurveyActions.SET_FORM_SUBMITING, formSubmiting: false });
        dispatch({ type: SurveyActions.SET_FORM_ERROR, formError: err.toString() });
      });
  };
}

// FORM SUBMIT - PUBLIC SURVEY
export function submitSurveyPublic(data) {
  return (dispatch) => {
    dispatch({ type: SurveyActions.SET_FORM_SUBMITING, formSubmiting: true });

    new Api()
      .submitSurveyPublic(data)
      .then((response) => {
        dispatch({ type: SurveyActions.SET_FORM_SUBMITING, formSubmiting: false });
        dispatch({
          type: SurveyActions.SET_FORM_SUBMITED,
          formResponse: response.data
        });
      })
      .catch((err) => {
        dispatch({ type: SurveyActions.SET_FORM_SUBMITING, formSubmiting: false });
        dispatch({ type: SurveyActions.SET_FORM_ERROR, formError: err.toString() });
      });
  };
}
