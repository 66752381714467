const accidentLocationTranslations = {
  en: {
    question: 'Where did it happen?',
    questionDescription: 'Give us the address where the accident took place',
    form: {
      cityLabel: 'City',
      cityPlaceholder: 'City of accident',
      addressLabel: 'Address',
      addressPlaceholder: 'Street, zip code, etc',
      countryLabel: 'Country',
      countryPlaceholder: 'Country of accident'
    },
    validation: {
      city: 'City location is required',
      country: 'Country is required',
      address: 'Address is required'
    }
  },
  de: {
    question: 'Wo ist es passiert?',
    questionDescription: 'Bitte geben Sie die Adresse des Unfallortes ein.',
    form: {
      cityLabel: 'Stadt',
      cityPlaceholder: 'Bspw. München, oder "nahe Köln"',
      addressLabel: 'Adresse',
      addressPlaceholder: 'Straße und Hausnummer, alternativ Autobahn oder Bundesstraßennummer',
      countryLabel: 'Land',
      countryPlaceholder: 'Unfallsland'
    },
    validation: {
      city: 'Angabe der Stadt wird benötigt',
      country: 'Angabe des Landes benötigt',
      address: 'Adresse wird benötigt'
    }
  }
};

export default accidentLocationTranslations;
