import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { i18nReducer } from 'react-redux-i18n';
// Import other reducers below

import userInfo from './user-info-reducer';
import progress from './progress-reducer';
import notification from './notification-reducer';
import claimForm from './form-reducer';
import broker from './broker-reducer';
import insuranceCompanies from './insurance-companies-reducer';
import submittedClaim from './submitted-claim-reducer';
import cookies from './cookies-reducer';
import surveyReducer from './survey-reducer';
import surveyResponseReducer from './survey-response-reducer';

const rootReducer = combineReducers({
  broker,
  insuranceCompanies,
  userInfo,
  progress,
  notification,
  claimForm,
  submittedClaim,
  form: formReducer,
  survey: surveyReducer,
  surveyResponse: surveyResponseReducer,
  i18n: i18nReducer,
  cookies
});

export default rootReducer;
