import React, { Component } from 'react';
import SVGInline from 'react-svg-inline';
import plusIcon from '../../../public/assets/icons/plus.svg';
import minusIcon from '../../../public/assets/icons/minus.svg';

class FaqItem extends Component {
  constructor(props) {
    super(props);

    this.clickedOnItem = this.clickedOnItem.bind(this);
    this.closeItem = this.closeItem.bind(this);

    this.state = {
      open: false
    };
  }

  clickedOnItem() {
    this.setState({ open: !this.state.open });
  }

  closeItem() {
    this.setState({ open: false });
  }

  render() {
    return (
      <div className={`faq-item ${this.state.open ? 'open' : ''}`}>
        <button className="title-button" type="button" onClick={this.clickedOnItem}>
          <span className="faq-title">{this.props.title}</span>
          <SVGInline svg={this.state.open ? minusIcon : plusIcon} />
        </button>
        {this.state.open && <div className="faq-body">{this.props.children}</div>}
        {/* {this.state.open && (
          <button className="close-button" onClick={this.closeItem}>
            {I18n.t("homePage.FAQ.closeFAQItemButton")}
          </button>
        )} */}
      </div>
    );
  }
}

export default FaqItem;
