const culpritTranslations = {
  en: {
    question: 'Do you know who is responsible for the damage?',
    questionDescription: 'If you know the culprit please provide all the contact details you have',
    culpritUnknown: 'Responsible person is unknown',
    form: {
      placeholders: {
        firstName: "Responsible persons's first name",
        lastName: "Responsible persons's last name",
        phone: "Responsible persons's phone number",
        email: "Responsible persons's email",
        address: "Responsible persons's address"
      }
    }
  },
  de: {
    question: 'Wissen Sie wer der Verursacher des Schadens ist?',
    questionDescription: 'Wenn Sie den Verursacher der Schaden kennen, bitte übermitteln Sie uns deren Daten',
    culpritUnknown: 'Verursacher ist unbekannt',
    form: {
      placeholders: {
        firstName: 'Vorname',
        lastName: 'Nachname',
        phone: 'Telefonnummer',
        email: 'E-Mail-Adresse',
        address: 'Adresse'
      }
    }
  }
};
export default culpritTranslations;
