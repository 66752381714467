const houseInitialState = {
  legalDomain: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    selectedIds: []
  },
  otherLegalDomain: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: ''
  },
  disputeDescription: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: ''
  },
  lawyerNeeded: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    answer: null
  },
  notes: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: ''
  },
  suggestLawyer: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    answer: null
  },
  suggestDamageSurveyor: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    answer: null
  },
  lastEditTime: null
};

export default houseInitialState;
