const lawyerNeededTranslations = {
  en: {
    question: 'Do you need a lawyer?',
    questionDescription: 'Select the answer'
  },
  de: {
    question: 'Brauchen Sie eine Empfehlung für einen spezialisierten Rechtsanwalt',
    questionDescription: 'Bitte Antwort wählen'
  }
};

export default lawyerNeededTranslations;
