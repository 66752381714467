import damagedItemsTranslations from './damaged-items';
import damageCircumstancesTranslations from './damage-circumstances';
import culpritTranslations from './culprit';
import propertyOwnerTranslations from './property-owner';
import suggestLawyerTranslations from './suggest-lawyer';

const houseFormTranslations = {
  en: {
    damagedItems: { ...damagedItemsTranslations.en },
    damageCircumstances: { ...damageCircumstancesTranslations.en },
    culprit: { ...culpritTranslations.en },
    propertyOwner: { ...propertyOwnerTranslations.en },
    suggestLawyer: { ...suggestLawyerTranslations.en }
  },
  de: {
    damagedItems: { ...damagedItemsTranslations.de },
    damageCircumstances: { ...damageCircumstancesTranslations.de },
    culprit: { ...culpritTranslations.de },
    propertyOwner: { ...propertyOwnerTranslations.de },
    suggestLawyer: { ...suggestLawyerTranslations.de }
  }
};

export default houseFormTranslations;
