import { Actions } from '../actions/action-types';
import initialState from './initial-state';

export default function progressReducer(state = initialState.progress, action) {
  switch (action.type) {
    case Actions.RESET_FROM: {
      // start with the initial state
      return { ...initialState.progress };
    }
    case Actions.SET_PROGRESS_PERCENTAGE:
      return { ...state, formCompletionPercentage: action.percentage };
    case Actions.SET_PROGRESS_STAGE:
      if (action.helloSignPreviouslyLoaded !== undefined && action.helloSignPreviouslyLoaded === true) {
        return {
          ...state,
          formNavigationStage: action.stage,
          helloSignPreviouslyLoaded: action.helloSignPreviouslyLoaded
        };
      }
      return { ...state, formNavigationStage: action.stage };
    case Actions.SHOW_ERROR_MODAL:
      return { ...state, serverError: action.error };
    case Actions.HIDE_ERROR_MODAL:
      return { ...state, serverError: '' };
    default:
      return state;
  }
}
