const homePageTranslations = {
  en: {
    gdprCookie: {
      button: 'Accept',
      text: 'By using this website, you agree to that this is optimized by cookies.'
    },
    coworkerQuestion:
      'May we contact the Schadenhilfe.online, as well as our external experts, in the future until further notice on your contract and claims matters by mail, e-mail and telephone?',
    details: 'Details',
    userForm: {
      title: 'Claim damages in only 8 minutes',
      description:
        'An accident is never an easy moment to go through. We make the claim process fast, easy and uncomplicated so you can focus on the essential: yourself. We take care of the rest',
      labels: {
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Email',
        coworker: 'Coworker',
        customer: 'Customer'
      },
      placeholders: {
        firstName: 'Type your first name',
        lastName: 'Type your last name',
        email: 'Type your email',
        name: 'Type your name'
      },
      errorMessages: {
        firstNameRequired: 'First name is required',
        lastNameRequired: 'Last name is required',
        emailRequired: 'Email is required',
        emailFormat: 'Wrong email format'
      },
      newsletter: 'I agree to receive the newsletter',
      claimButton: 'Make a damage claim now',
      claimExistingButton: 'Inquiry about damage already reported',
      getStarted: 'Get started now',
      claimSubmitted: {
        text: 'Already submitted a claim and no response?',
        button: 'Click here'
      },
      readyToStartClaim: 'Ready to claim your damages?',
      ourPartners: 'Partners'
    },
    testimonials: {
      title: 'Testimonials:'
    },
    FAQ: {
      closeFAQItemButton: 'Got it'
    },
    whyUse: {
      title: 'Why should you use SchadenHilfe?',
      saveTimeTitle: 'Save time & avoid frustration',
      saveTimeDescription:
        'With SchadenHilfe, you can do all your insurance work online, without any unnecessary, time-consuming and frustrating trips to your insurance company’s physical location.',
      insuranceTermsTitle: 'Your insurance terms stay the same',
      insuranceTermsDetails:
        'Accidents happen — it’s in our human nature. No matter who was at fault regarding your incident, we’ll do our best to cover the damages.',
      hotlineTitle: 'No need to deal with a bad hotline',
      hotlineDescription:
        'Customer service hotline experiences are horrible, especially when you have to wait for 10 minutes just to be redirected to another operator. Save time with SchadenHilfe by doing everthing online!',
      coverageTitle: 'You always get the best coverage',
      coverageDetails:
        'Just because you run out of inspiration to write descriptions doesn’t mean you don’t get your damages refunded. Get started now!'
    }
  },
  de: {
    gdprCookie: {
      button: 'In Ordnung',
      text: `Durch die Nutzung dieser Website erklären Sie sich damit einverstanden, 
      dass diese durch Cookies optimiert wird.`
    },
    details: 'Detailangaben',
    coworkerQuestion:
      'Dürfen wir und unsere externen Experten Sie künftig bis auf Widerruf zu Ihren Vertrags- und Schadenangelegenheiten per Post, Mail und Telefon kontaktieren?',
    userForm: {
      title: 'Schadenmanager in nur 8 Minuten',
      description:
        'Melden Sie Ihren Versicherungsschaden, einfach und kostenlos. Wir sind Ihr kostenloser Schadenmanager für jede Versicherung in Deutschland, für jeden Schaden.',
      labels: {
        firstName: 'Vorname',
        lastName: 'Nachname',
        email: 'Email-Adresse',
        coworker: 'Agent:in',
        customer: 'Kund:in'
      },
      placeholders: {
        firstName: 'Vornamen',
        lastName: 'Nachname',
        email: 'E-Mail-Adresse',
        name: 'Bitte tragen Sie Ihre Identifkation ein.'
      },
      errorMessages: {
        firstNameRequired: 'Vorname wird benötigt',
        lastNameRequired: 'Nachname wird benötigt',
        emailRequired: 'Email-Adresse wird benötigt',
        emailFormat: 'Falsches Format der Email-Adresse'
      },
      newsletter: 'Ich stimme zu die Allgemeine Geschäftsbedingungen und die Datenschutzerklärung',
      claimButton: 'Jetzt neuen Schaden melden',
      claimExistingButton: 'Nachfrage zu bestehender Schadennummer',
      getStarted: 'Jetzt loslegen',
      claimSubmitted: {
        text: 'Besteht schon eine Schadenakte, aber Sie haben eine Nachfrage?',
        button: 'Klicken Sie hier'
      },
      readyToStartClaim: 'Starten Sie jetzt.',
      ourPartners: 'In Partnerschaft mit'
    },
    testimonials: {
      title: 'Vielen zufriedenen Kunden konnten wir bereits helfen:'
    },
    FAQ: {
      closeFAQItemButton: 'Einverstanden'
    },
    whyUse: {
      title: 'Warum Schadenhilfe?',
      saveTimeTitle: 'Sparen Sie Zeit & vermeiden Sie Ärger',
      saveTimeDescription:
        'Über Schadenhilfe.online können Sie jederzeit, bei jeder Gesellschaft Ihren Schaden melden. Kein Detail wird vergessen und Sie vermeiden nervige Nachfragen.',
      insuranceTermsTitle: 'An Ihrer Versicherung ändert sich nichts',
      insuranceTermsDetails:
        'An Ihren Verträgen ändert sich natürlich nichts. Wir sind ausdrücklich kein Versicherungsvertrieb und müssen Ihnen daher auch keine Versicherungen verkaufen. ',
      hotlineTitle: 'keine nervige Hotline',
      hotlineDescription:
        'Bei uns hängen Sie in keiner Hotline oder Warteschleife, wir fragen Sie alles was wichtig ist und sorgen für eine schnelle Schadenmeldung.',
      coverageTitle: 'Keine Erstattung wird vergessen',
      coverageDetails:
        'Bei einem Schadenfall greifen oft viele Versicherungen. Mit uns und unseren Partnern behalten Sie den Überblick und es wird keine Erstattung vergessen.'
    }
  }
};

export default homePageTranslations;
