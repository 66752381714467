const userDetailsTranslations = {
  en: {
    question: 'Your contact details',
    questionDescription: 'Please let us know how we can reach you',
    form: {
      labels: {
        zip: 'Zip Code',
        company: 'Company name (optional)',
        birthDate: 'Birth Date'
      },
      placeholders: {
        phone: 'Type your phone',
        country: 'Select the country',
        city: 'Your city',
        address: 'Your address',
        zip: 'Zip code',
        company: 'Your company name'
      },
      errors: {
        phone: 'Phone required',
        zip: 'Zip code required',
        birthDate: 'Birth date required'
      }
    }
  },
  de: {
    question: 'Ihre Kontaktdaten',
    questionDescription: 'Bitte teilen Sie uns zum Abschluss noch Ihre Kontaktdaten mit.',
    form: {
      labels: {
        zip: 'Postleitzahl',
        company: 'Firmenname (optional)',
        birthDate: 'Geburtsdatum'
      },
      placeholders: {
        phone: 'Bitte tragen Sie Ihre Telefonnummer für Rückfragen ein.',
        country: 'Bitte Land auswählen',
        city: 'Bitte tragen Sie die Stadt Ihres Wohnorts ein.',
        address: 'Bitte tragen Sie Ihre Straße und Hausnummer ein.',
        zip: 'Postleitzahl',
        company: 'Firmenname, wenn zutreffend.'
      },
      errors: {
        phone: 'Telefonnummer wird benötigt',
        zip: 'Postleitzahl wird benötigt',
        birthDate: 'Geburtsdatum wird benötigt'
      }
    }
  }
};

export default userDetailsTranslations;
