const vehicleTypeTranslations = {
  en: {
    question: 'What kind of vehicle do you have',
    questionDescription: 'Select one vehicle type',
    options: {
      car: 'Car',
      moto: 'Motorbike',
      others: 'Others'
    }
  },
  de: {
    question: 'Um welche Fahrzeugart handelt es sich?',
    questionDescription: 'Bitte wählen Sie eine Fahrzeugart aus.',
    options: {
      car: 'Auto',
      moto: 'Motorrad',
      others: 'Anderes Fahrzeug'
    }
  }
};

export default vehicleTypeTranslations;
