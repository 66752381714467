const existingFormTranslations = {
  en: {
    firstNamePlaceholder: 'Your first name',
    lastNamePlaceholder: 'Your last name',
    phone: 'Type phone',
    email: 'Your email',
    contractHolderQuestion: 'Are you a contract holder?',
    contractHolderYes: 'Yes',
    contractHolderNo: 'No',
    victimCheckbox: 'I am a victim',
    insuranceCompanyQuestion: 'With which company you have submitted the claim?',
    insuranceCompanyPlaceholder: 'Insurance Company',
    insuranceCompanyError: 'Insurance company is required',
    referenceNumberQuestion: 'Damage number / claim number / refeerence number',
    referenceNumberPlaceholder: 'Type the reference number',
    referenceNumberError: 'Reference number is required',
    notesQuestion: 'Give us more details',
    notesPlaceholder: 'Type anything that will help us find your claim',
    agreePrivacyStatement: 'I agree with the privacy statement.'
  },
  de: {
    firstNamePlaceholder: 'Vorname',
    lastNamePlaceholder: 'Nachname',
    phone: 'Telefonnummer',
    email: 'Email-Adresse',
    contractHolderQuestion: 'Sind Sie Vertragsnehmer?',
    contractHolderYes: 'Ja',
    contractHolderNo: 'Nein',
    victimCheckbox: 'Ich bin Geschädigte(r)',
    insuranceCompanyQuestion: 'An welche Versicherung wurde der Schaden gemeldet?',
    insuranceCompanyPlaceholder: 'Versicherung',
    insuranceCompanyError: 'Angabe der Versicherung ist notwendig',
    referenceNumberQuestion: 'Schadensfallnummer / Antragsnummer / Referenz',
    referenceNumberPlaceholder: 'Bitte eine Referenz angeben',
    referenceNumberError: 'Angabe der Referenz ist notwendig',
    notesQuestion: 'Konkrete Nachfrage an die Versicherung',
    notesPlaceholder: 'Bitte schildern Sie das aktuellen Problem',
    agreePrivacyStatement: 'Ich stimme der Datenschutzerklärung zu'
  }
};

export default existingFormTranslations;
