const suggestLawyerTranslation = {
  en: {
    question: 'Do you need a lawyer?',
    questionDescription: 'Please select answer'
  },
  de: {
    question: 'Wünschen Sie eine Reparatur über unsere zertifizierte Partnerwerkstatt?',
    questionDescription: 'Bitte wählen Sie die zutreffende Antwort aus.'
  }
};

export default suggestLawyerTranslation;
