/* eslint-disable no-case-declarations */
import { SurveyActions } from '../actions/survey-actions';

const initialState = {
  connectedData: null,
  connectedDataText: null,

  fearOfDamage: [], // MULTICHOICE, TEXT{
  peopleAtHome: '',
  childrenAtHome: '',
  animalsAtHome: 'false', // needs to be string, because of redux-form
  hasApartment: false,
  hasHouse: false,
  squareMeters: '',
  livingRented: false, // HOUSE OR APARMENT RENTED
  livingOwned: false, // HOUSE OR APARMENT PRIVATELY OWNED,
  carsAtHome: '',
  mostExpensiveCarWorth: '',
  numberOfInsuranceClaimsIn3Years: '',
  allInsuranceClaimsPaid: 'false', // needs to be string, because of redux-form
  whatIsImportantToYou: '',

  country: 'Deutschland', // survey public
  birthdate: '' // survey public
};

export default function surveyReducer(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
    // FEAR OF DAMAGE
    // payload: string
    case SurveyActions.SET_FEAR_OF_DAMAGE:
      var ids = state.fearOfDamage;
      ids.includes(payload) ? ids.splice(ids.indexOf(payload), 1) : ids.push(payload);

      return {
        ...state,
        fearOfDamage: ids
      };

    // HAS APARTMENT
    case SurveyActions.SET_HAS_APARTMENT:
      return {
        ...state,
        hasApartment: true,
        hasHouse: false
      };

    // HAS HOUSE
    case SurveyActions.SET_HAS_HOUSE:
      return {
        ...state,
        hasApartment: false,
        hasHouse: true
      };

    // LIVING RENTED
    case SurveyActions.SET_LIVIN_RENTED:
      return {
        ...state,
        livingRented: true,
        livingOwned: false
      };

    // LIVING OWNED
    case SurveyActions.SET_LIVIN_OWNED:
      return {
        ...state,
        livingRented: false,
        livingOwned: true
      };

    // CONNECT DATA ID
    case SurveyActions.SET_CONNECT_DATA_ID:
      return {
        ...state,
        connectedData: action.connectDataId,
        connectedDataText: action.connectedDataText
      };

    // RESET FORM
    case SurveyActions.SET_FORM_RESET:
      return initialState;

    default:
      return state;
  }
}
