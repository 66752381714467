const witnessesTranslations = {
  en: {
    question: 'Were there witnesses?',
    questionDescription: 'If you have witnesses details, please write them below, only the names are mandatory',
    noWitnesses: 'No witnesses available',
    addAnotherWitness: 'Add another witness',
    selectedWitnessLabel: 'Witness',
    form: {
      labels: {
        firstName: 'First name',
        lastName: 'Last name',
        phone: 'Phone',
        email: 'Email',
        address: 'Address'
      },
      placeholders: {
        firstName: 'Witness first name',
        lastName: 'Witness last name',
        phone: 'Type the phone number of the witness',
        email: 'Email of the witness',
        address: 'Witness address'
      },
      errorMessage: {
        firstNameRequired: 'First name of the witness is required',
        lastNameRequired: 'Last name of the witness is required'
      },
      removeWitness: 'Remove'
    }
  },
  de: {
    question: 'Gab es Zeugen?',
    questionDescription: 'Bitte Angaben zu Zeugen machen soweit diese vorliegen',
    noWitnesses: 'Keine Zeugenangaben vorliegend',
    addAnotherWitness: 'Zeugenangaben hinzufügen',
    selectedWitnessLabel: 'Zeuge/Zeugin',
    form: {
      labels: {
        firstName: 'Vorname',
        lastName: 'Nachname',
        phone: 'Telefonnummer',
        email: 'Email-Adresse',
        address: 'Adresse'
      },
      placeholders: {
        firstName: 'Vorname des Zeugen / der Zeugin',
        lastName: 'Nachname des Zeugen / der Zeugin',
        phone: 'Telefonnummer des Zeugen / der Zeugin',
        email: 'Email-Adresse des Zeugen / der Zeugin',
        address: 'Adresse des Zeugen / der Zeugin'
      },
      errorMessage: {
        firstNameRequired: 'Vorname wird benötigt',
        lastNameRequired: 'Nachname wird benötigt'
      },
      removeWitness: 'Entfernen'
    }
  }
};

export default witnessesTranslations;
