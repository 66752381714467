const vandalismCircumstancesTranslations = {
  en: {
    question: 'Circumstances of the vandalism act?',
    questionDescription: 'Please describe as clear as possible what happened',
    placeholder: 'Detail the vandalism results'
  },
  de: {
    question: 'Umstände des Vandalismus?',
    questionDescription: 'Bitte möglichst genaue Angaben zum Vandalismus machen',
    placeholder: 'Genauere Informationen zum Vandalismusschaden'
  }
};

export default vandalismCircumstancesTranslations;
