const whatWasDamagedTranslations = {
  en: {
    question: 'Who or what got damaged?',
    questionDescription: 'Select all that applies',
    options: {
      vehicle: 'Vehicle',
      person: 'Person',
      belongings: 'Belongings'
    }
  },
  de: {
    question: 'Wer oder was wurde geschädigt?',
    questionDescription: 'Bitte wählen Sie alle zutreffende Antworten aus.',
    options: {
      vehicle: 'Fahrzeug',
      person: 'Person',
      belongings: 'Sonstiges Eigentum'
    }
  }
};

export default whatWasDamagedTranslations;
