/* eslint-disable no-case-declarations */
import { SurveyActions } from '../actions/survey-actions';

const initialState = {
  formSubmiting: false,
  formResponse: null,
  formError: null
};

export default function surveyResponseReducer(state = initialState, action) {
  switch (action.type) {
    // FORM SUBMITING
    case SurveyActions.SET_FORM_SUBMITING:
      return {
        ...state,
        formSubmiting: action.formSubmiting
      };

    // SUBMIT FORM - SUCCESS
    case SurveyActions.SET_FORM_SUBMITED:
      return {
        ...state,
        formResponse: action.formResponse
      };

    // SUBMIT FORM - ERROR
    case SurveyActions.SET_FORM_ERROR:
      return {
        ...state,
        formError: action.formError
      };

    default:
      return state;
  }
}
