const thankYouStepTranslations = {
  en: {
    topTitle: 'All done, thank you',
    details:
      'Thanks, the damage report is ready and will be processed! Our partner will contact you as soon as possible and will gladly assist you with the transaction. Service, as well as additional support are free of charge and the contract changes neither contribution nor performance.',
    helpTitle: 'But we can help you further to deal with your {{accidentType}} accident.',
    helpDescription: 'We have gathered many helpful providers for your case on the following page.',
    affiliatePartnersButton: 'Show me the help i can get',
    weFoxMessage: 'Continue to weFox to sign a form',
    simpleCheckMessage:
      'For the communication with the responsible insurance companies, our partner must of course be legitimized by you for legal reasons.',
    simpleCheckButton: 'Legitimization with Simple Check'
  },
  de: {
    topTitle: 'Geschafft, danke!',
    details:
      'Danke, die Schadenmeldung ist damit fertig und wird bearbeitet! Unser Partner meldet sich schnellst möglich bei Ihnen und unterstützt Sie bei der Abwicklung. Unser Service ist kostenfrei und an Ihrem Vertrag ändert sich weder Beitrag, noch Leistung.',
    helpTitle: 'Neben der Meldung helfen wir gerne auch bei der Behebung des Schadens.',
    helpDescription: 'Hier haben wir Ihnen die besten Kooperationen in diesem Bereich zusammengestellt.',
    affiliatePartnersButton: 'Zur besten Hilfe',
    weFoxMessage:
      'Die Legitimierung unseres Partner können Sie gleich hier in max. 5 Minuten online auch mobil abschließen.',
    simpleCheckMessage:
      'Für die Kommunikation mit den zuständigen Versicherungsgesellschaften muss unser Partner aus rechtlichen Gründen natürlich von Ihnen legitimiert sein.',
    simpleCheckButton: 'Legitimierung mit Simple Check'
  }
};

export default thankYouStepTranslations;
