const countriesTranslations = {
  en: {
    list:
      'Albania,Andorra,Austria,Belarus,Belgium,Bosnia and Herzegovina,Bulgaria,Czech Republic,Denmark,Germany,' +
      'England,Estonia,Finland,France,Hungary,Greece,Ireland,Iceland,Italy,Croatia,Latvia,Liechtenstein,' +
      'Lithuania,Luxembourg,Malta,Macedonia,Moldova,Monaco,Montenegro,Netherlands,' +
      'Northern Ireland,Norway,Poland,Portugal,Romania,Russia,San Marino,Scotland,Sweden,' +
      'Switzerland,Serbia,Slovakia,Slovenia,Spain,Ukraine,United Kingdom,Vatican City,Wales',
    defaultCountry: 'Germany'
  },
  de: {
    list:
      'Albanien,Andorra,Belgien,Bosnien und Herzegowina,Bulgarien,Dänemark,Deutschland,' +
      'England,Estland,Finnland,Frankreich,Griechenland,Irland,Island,Italien,Kroatien,Lettland,Liechtenstein,Litauen,' +
      'Luxemburg,Malta,Mazedonien,Moldawien,Monaco,Montenegro,Niederlande,Nordirland,' +
      'Norwegen,Österreich,Polen,Portugal,Rumänien,Russland,San Marino,Schottland,Schweden,' +
      'Schweiz,Serbien,Slowakei,Slowenien,Spanien,Tschechien,Ukraine,Ungarn,Vatikanstadt,Vereinigtes Königreich,Wales,Weißrussland',
    defaultCountry: 'Deutschland'
  }
};

export default countriesTranslations;
