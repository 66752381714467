import damageCausetranslations from './damage-cause';
import damageCircumstancesTranslations from './damage-circumstances';

const houseFormTranslations = {
  en: {
    damageCause: { ...damageCausetranslations.en },
    damageCircumstances: { ...damageCircumstancesTranslations.en }
  },
  de: {
    damageCause: { ...damageCausetranslations.de },
    damageCircumstances: { ...damageCircumstancesTranslations.de }
  }
};

export default houseFormTranslations;
