import { Actions } from '../actions/action-types';
import initialState from './initial-state';

export default function userInfoReducer(state = initialState.submittedClaimData, action) {
  switch (action.type) {
    case Actions.RESET_FROM: {
      // start with the initial state
      return null;
    }
    case Actions.SAVE_CLAIM_DATA: {
      const { serverResponse, step3Data, leadProcessingJourney } = action.data;
      return { ...serverResponse, step3Data, leadProcessingJourney };
    }
    case Actions.CLEAR_HELLOSIGN_INFO: {
      return { ...state, embeddedSignatureRequest: undefined };
    }
    case Actions.SAVE_PAID_OPTION:
      return { ...state, paidOption: true };
    case Actions.SAVE_WEFOX_VERSION:
      return { ...state, wefoxVersion: true };
    default:
      return state;
  }
}
