const damagedItemsTranslations = {
  en: {
    question: 'What item was damaged?',
    questionDescription: 'Please select the option which describes best what happened',
    otherDomainTitle: 'Please describe the damaged item',
    otherDomainPlaceholder: 'Description of item',
    options: {
      devices: 'Smartphone, Laptop, Tablet',
      bicycle: 'Bicycle',
      glasses: 'Glasses',
      others: 'Others'
    }
  },
  de: {
    question: 'Was wurde beschädigt?',
    questionDescription: 'Bitte die am ehesten zutreffende Option auswählen',
    otherDomainTitle: 'Bitte das beschädigte Eigentum beschreiben',
    otherDomainPlaceholder: 'Beschreibung des Eigentums',
    options: {
      devices: 'Smartphone, Laptop, Tablet',
      bicycle: 'Fahrrad',
      glasses: 'Brille',
      others: 'Andere'
    }
  }
};

export default damagedItemsTranslations;
