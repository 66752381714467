const disputeDescriptionTranslations = {
  en: {
    question: 'Circumstances of the dispute',
    questionDescription: 'Please describe the dispute',
    placeholder: 'Details of dispute'
  },
  de: {
    question: 'Umstände des Streitfalls',
    questionDescription: 'Bitte den Streitfall beschreiben',
    placeholder: 'Genauere Angaben zum Streitfall'
  }
};

export default disputeDescriptionTranslations;
