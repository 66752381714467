import homePageTranslations from './home-page';
import globalFormTranslations from './global-form';
import carFormTranslations from './car-form/index';
import countriesTranslations from './countries';
import houseFormTranslations from './house-form';
import personFormTranslations from './person-form';
import legalFormTranslations from './legal-form';
import othersFormTranslations from './others-form';
import finalStepTranslations from './final-step';
import mandateStepTranslations from './mandate-step';
import existingFormTranslations from './existing-form';
import thankYouStepTranslations from './thank-you-step';
import payStepTranslations from './pay-step';
import affilatesTranslations from './affiliates';
import summaryStepTranslations from './summary-step';
import callbackFormTranslations from './callback-form';

const translationsObject = {
  en: {
    homePage: { ...homePageTranslations.en },
    globalForm: { ...globalFormTranslations.en },
    carForm: { ...carFormTranslations.en },
    houseForm: { ...houseFormTranslations.en },
    personForm: { ...personFormTranslations.en },
    legalForm: { ...legalFormTranslations.en },
    othersForm: { ...othersFormTranslations.en },
    countries: { ...countriesTranslations.en },
    finalStep: { ...finalStepTranslations.en },
    mandateStep: { ...mandateStepTranslations.en },
    payStep: { ...payStepTranslations.en },
    thankYouStep: { ...thankYouStepTranslations.en },
    existingForm: { ...existingFormTranslations.en },
    callbackForm: { ...callbackFormTranslations.en },
    affiliates: { ...affilatesTranslations.en },
    summary: { ...summaryStepTranslations.en }
  },
  de: {
    homePage: { ...homePageTranslations.de },
    globalForm: { ...globalFormTranslations.de },
    carForm: { ...carFormTranslations.de },
    houseForm: { ...houseFormTranslations.de },
    personForm: { ...personFormTranslations.de },
    legalForm: { ...legalFormTranslations.de },
    othersForm: { ...othersFormTranslations.de },
    countries: { ...countriesTranslations.de },
    finalStep: { ...finalStepTranslations.de },
    mandateStep: { ...mandateStepTranslations.de },
    payStep: { ...payStepTranslations.de },
    thankYouStep: { ...thankYouStepTranslations.de },
    existingForm: { ...existingFormTranslations.de },
    callbackForm: { ...callbackFormTranslations.de },
    affiliates: { ...affilatesTranslations.de },
    summary: { ...summaryStepTranslations.de }
  }
};

export default translationsObject;
