import { Actions } from '../actions/action-types';
import initialState from './initial-state';

export default function notificationReducer(state = initialState.notification, action) {
  switch (action.type) {
    case Actions.SHOW_NOTIFICATION_MODAL: {
      const { applyFunction, cancelFunction, formName, editTime } = action.payload;
      return {
        ...state,
        show: true,
        applyFunction,
        cancelFunction,
        formName,
        editTime
      };
    }
    case Actions.HIDE_NOTIFICATION_MODAL:
      return { ...initialState.notification };
    default:
      return state;
  }
}
