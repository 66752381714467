import userDetailsTranslations from './user-details';
import insuranceDetailsTranslations from './insurance-details';
import bankingDetailsTranslations from './banking-details';

const finalStepTranslations = {
  en: {
    userDetails: { ...userDetailsTranslations.en },
    insuranceDetails: { ...insuranceDetailsTranslations.en },
    bankingDetails: { ...bankingDetailsTranslations.en },
    submitFormButton: 'Continue',
    submitInvalid: 'Please fill-in all required fields to proceed'
  },
  de: {
    userDetails: { ...userDetailsTranslations.de },
    insuranceDetails: { ...insuranceDetailsTranslations.de },
    bankingDetails: { ...bankingDetailsTranslations.de },
    submitFormButton: 'Absenden',
    submitInvalid: 'Bitte füllen Sie alle erforderlichen Felder aus'
  }
};

export default finalStepTranslations;
