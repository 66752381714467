const damageCircumstancesTranslations = {
  en: {
    question: 'Circumstances of the accident?',
    questionDescription: 'Please describe the accident',
    placeholder: 'Details of what happened'
  },
  de: {
    question: 'Umstände des Unfalls?',
    questionDescription: 'Bitte den Unfall beschreiben',
    placeholder: 'Genauere Angaben zum Unfallhergang'
  }
};

export default damageCircumstancesTranslations;
