import React from 'react';
import FaqItem from './faq-item';

const Faq = () => (
  <div className="container">
    <div className="row homepage-row faq-container">
      <div className="col-sm-2" />
      <div className="col-sm-8">
        <h2>Häufig gestellte Fragen</h2>
        <FaqItem title="Was ist die Schadenhilfe.online?">
          Als Partner von bundesweit tätigen Versicherungsexperten stehen wir für schnelle und professionelle Hilfe im
          Schadenfall. Gerade im Schadenfall zeigt es sich wie gut eine Versicherung ist. Über unsere zertifizierten
          Partner mit eigener Rechtsabteilung gewährleisten wir die bestmögliche und vor allem unabhängige Unterstützung
          bei der Abwicklung mit Ihrer bestehenden Versicherung. Für Sie ist der komplette Service kostenlos. Natürlich
          ändert sich an Ihren Versicherungen nichts.
        </FaqItem>
        <FaqItem title="Was kostet mich der Service?">
          Über unsere Partner ist unsere Leistung komplett kostenlos.
        </FaqItem>
        <FaqItem title="Wieso sollte ich den Schaden nicht direkt bei der Versicherung melden?">
          Unser Ziel ist es, dass Sie schnell und einfach Ihren kompletten Schaden melden können, keine lässigen
          Nachfragen kommen und Sie wirklich alle Versicherungsleistungen bekommen, die möglich sind. Wir sind
          unabhängig und sind IHRE Schadenhilfe!
        </FaqItem>
        <FaqItem title="Wie schnell wird mein Fall bearbeitet?">
          Ihre Schadenmeldung wird schnellst möglich gesichtet, auf Plausibilität geprüft und im Normalfall direkt an
          die zuständige Schadenbearbeiter Ihrer Versicherung weitergeleitet. Über unsere Kooperationen haben wir einen
          sehr schnellen und direkten Zugang auch zu Ihrer Versicherung.
        </FaqItem>
        <FaqItem title="Was leistet die Schadenhilfe.online?">
          Wir sorgen für Ihre möglichst schnelle Schadenmeldung mit wirklich allen relevanten Angaben bei der
          Versicherung und vermeiden lästige, unnötige Nachfragen. Über unsere Partner erhalten Sie zusätzliche Hilfe,
          Beratung und sparen Geld bei Ihren Versicherungen.
        </FaqItem>
        <FaqItem title="Ändert sich etwas an meiner Versicherung?">
          An Ihren Verträgen ändert sich natürlich nichts. Wir sind ausdrücklich kein Versicherungsvertrieb und müssen
          Ihnen daher auch keine Versicherungen verkaufen. Sämtliche Vertragsänderungen müssen auch in Zukunft von Ihnen
          abgesegnet werden.
        </FaqItem>
        <FaqItem title="Mit welchen Versicherungen arbeitet Schadenhilfe.online zusammen?">
          Mit allen, ohne Ausnahme, egal ob Premium- oder Online-Versicherung!
        </FaqItem>
        <FaqItem title="Wer sind die Partner der Schadenhilfe.online?">
          Unsere Partner sind ausschließlich bundesweit tätige, zertifizierte Versicherungsexperten. Aufgrund laufender
          Serviceverträge mit allen Versicherungen, werden auch unsere Partner direkt von der Versicherung bezahlt. Für
          Sie ist damit der komplette Service kostenlos. Natürlich ändert sich an Ihren Versicherungen nichts ohne Ihre
          Zustimmung.
        </FaqItem>
        <FaqItem title="Habe ich einen direkten Ansprechpartner?">
          Unsere Partner stehen Ihnen auch später jederzeit persönlich zur Verfügung. Im aktuellen Schadenfall bringen
          wir Sie schnellst möglich mit dem zuständigen Schadenbeauftragten Ihrer Versicherung in Kontakt und ersparen
          Ihnen lästige Hotlinetelefonate.
        </FaqItem>
        {/* <FaqItem title="Hilfreiche Links">
          Bitte klicken Sie <a href="/affiliates">hier</a>.
        </FaqItem> */}
      </div>
      <div className="col-sm-2" />
    </div>
  </div>
);

export default Faq;
