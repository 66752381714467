const affilatesTranslations = {
  en: {
    title: 'We recommend the following partners',
    description:
      'Because an accident doesn’t end with the claim, we have made a selection of partners that will help you deal with your situation',
    button: 'Visit partner',
    categories: {
      car: 'Car',
      house: 'House',
      person: 'Person',
      legal: 'Legal',
      others: 'Others'
    }
  },
  de: {
    title: 'Perfekte Hilfe über unser Netzwerk:',
    description:
      'Ein Schaden ist nicht durch die Meldung behoben, deshalb helfen wir Ihnen auch bei der Behebung des Schadens.',
    button: 'Zum Partner',
    categories: {
      car: 'Kfz-Schaden',
      house: 'Gebäudeschaden',
      person: 'Personenschaden',
      legal: 'Rechtsstreit',
      others: 'Handy, Brille und mehr'
    }
  }
};

export default affilatesTranslations;
