/**
 * This is the first section of "accident with animals"
 */

const animalCauseTranslations = {
  en: {
    question: 'What is the cause?',
    questionDescription: 'Please select the option which describes best what happened',
    options: {
      accidentWithWildlife: 'Accident with wildlife',
      damageMadeByAnimals: 'Damages made by animals'
    }
  },
  de: {
    question: 'Was ist die Ursache?',
    questionDescription: 'Bitte wählen Sie die Option, die ab ehesten zutrifft.',
    options: {
      accidentWithWildlife: 'Unfall mit Wildtieren',
      damageMadeByAnimals: 'Schäden durch Tiere'
    }
  }
};

export default animalCauseTranslations;
