/**
 * This is the first section of "accident with animals"
 */

const theftCauseTranslations = {
  en: {
    question: 'What is the cause?',
    questionDescription: 'Please select the option which describes best what happened',
    options: {
      vehicleStolen: 'Vehicle was stolen',
      partsStolen: 'Inner or outer vehicle parts were stolen',
      unsuccessfullAttempt: 'Unsuccessful theft attempt'
    }
  },
  de: {
    question: 'Was ist die Ursache?',
    questionDescription: 'Bitte die am ehesten zutreffende Option auswählen',
    options: {
      vehicleStolen: 'Fahrzeug gestohlen',
      partsStolen: 'Fahrzeugteile gestohlen',
      unsuccessfullAttempt: 'Diebstahlversuch'
    }
  }
};

export default theftCauseTranslations;
