import React from 'react';
import { I18n } from 'react-redux-i18n';
import SVGInline from 'react-svg-inline';

import saveTimeIcon from '../../public/assets/icons/save-time.svg';
import contractIcon from '../../public/assets/icons/contract.svg';
import hotlineIcon from '../../public/assets/icons/hotline.svg';
import coverageIcon from '../../public/assets/icons/coverage.svg';

const WhyUse = () => (
  <div className="why-use-container">
    <div className="container-fluid full-width-image">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>{I18n.t('homePage.whyUse.title')}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-1 hidden-xs-down" />
          <div className="col-xs-12 col-sm-5">
            <div className="why-use-item">
              <div className="why-use-icon">
                <SVGInline svg={saveTimeIcon} />
              </div>
              <div className="why-use-text">
                <div className="why-use-title">{I18n.t('homePage.whyUse.saveTimeTitle')}</div>
                <div className="why-use-body">{I18n.t('homePage.whyUse.saveTimeDescription')}</div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-5">
            <div className="why-use-item">
              <div className="why-use-icon">
                <SVGInline svg={contractIcon} />
              </div>
              <div className="why-use-text">
                <div className="why-use-title">{I18n.t('homePage.whyUse.insuranceTermsTitle')}</div>
                <div className="why-use-body">{I18n.t('homePage.whyUse.insuranceTermsDetails')}</div>
              </div>
            </div>
          </div>
          <div className="col-sm-1 hidden-xs-down" />
        </div>
        <div className="row">
          <div className="col-sm-1 hidden-xs-down" />
          <div className="col-xs-12 col-sm-5">
            <div className="why-use-item">
              <div className="why-use-icon">
                <SVGInline svg={hotlineIcon} />
              </div>
              <div className="why-use-text">
                <div className="why-use-title">{I18n.t('homePage.whyUse.hotlineTitle')}</div>
                <div className="why-use-body">{I18n.t('homePage.whyUse.hotlineDescription')}</div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-5">
            <div className="why-use-item">
              <div className="why-use-icon">
                <SVGInline svg={coverageIcon} />
              </div>
              <div className="why-use-text">
                <div className="why-use-title">{I18n.t('homePage.whyUse.coverageTitle')}</div>
                <div className="why-use-body">{I18n.t('homePage.whyUse.coverageDetails')}</div>
              </div>
            </div>
          </div>
          <div className="col-sm-1 hidden-xs-down" />
        </div>
        {/* <div className="row button-row">
          <div className="col-12">
            <a href="/meldung">
              <div className="btn btn-lg claim-button">{I18n.t('homePage.userForm.claimButton')}</div>
            </a>
          </div>
        </div> */}
      </div>
    </div>
  </div>
);

export default WhyUse;
