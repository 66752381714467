const bankingDetailsTranslations = {
  en: {
    question: 'Your banking details',
    questionDescription: 'Where should the repair money arrive?',
    bankTransfer: 'I want to receive repairs per bank transfer',
    taxEligibilityTitle: 'Tax eligibility',
    taxEligibilityDescription: 'Are you able to put off some of the fees in taxes?',
    form: {
      labels: {
        iban: 'IBAN',
        bic: 'BIC'
      },
      placeholders: {
        iban: 'Type your account IBAN',
        bic: 'Type BIC number'
      },
      errors: {
        ibanRequired: 'IBAN number is required',
        ibanWrong: 'IBAN has a wrong format',
        bicRequired: 'BIC is required',
        bicWrong: 'BIC has a wrong format'
      }
    }
  },
  de: {
    question: 'Ihre Bankdaten',
    questionDescription: 'Auf welchem Konto möchten Sie die Kostenerstattung erhalten?',
    bankTransfer: 'Ich möchte Erstattungen per Bankzahlung bekommen',
    taxEligibilityTitle: 'Steuerliche Absetzung',
    taxEligibilityDescription: 'Sind Sie berechtigt, Kosten steuerlich abzusetzen?',
    form: {
      labels: {
        iban: 'IBAN',
        bic: 'BIC (optional)'
      },
      placeholders: {
        iban: 'Bitte tragen Sie Ihre IBAN ein.',
        bic: 'Bitte tragen Sie Ihre BIC ein.'
      },
      errors: {
        ibanRequired: 'IBAN wird benötigt',
        ibanWrong: 'Formatfehler bei IBAN',
        bicRequired: 'BIC wird benötigt',
        bicWrong: 'Formatfehler bei BIC'
      }
    }
  }
};

export default bankingDetailsTranslations;
