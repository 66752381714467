/**
 * This is the first section of "legal damage"
 */

const legalDomainTranslations = {
  en: {
    question: 'In which area of law?',
    questionDescription: 'Please select the option which describes best what happened',
    otherDomainTitle: 'Please describe the other law domain',
    otherDomainPlaceholder: 'Description of other law domain',
    options: {
      employment: 'Employment law',
      tenancy: 'Tenancy law',
      trafficFines: 'Fines related to traffic laws',
      otherFines: 'Fines not related to traffic laws',
      social: 'Social law',
      family: 'Family law',
      inheritance: 'Inheritance law',
      consumer: 'Consumer law',
      others: 'Others'
    }
  },
  de: {
    question: 'Welcher Rechtsbereich?',
    questionDescription: 'Bitte die am ehesten zutreffende Option auswählen',
    otherDomainTitle: 'Bitte den Rechtsbereich beschreiben',
    otherDomainPlaceholder: 'Beschreibung des Rechtsbereichs',
    options: {
      employment: 'Arbeitsrecht',
      tenancy: 'Mietrecht',
      trafficFines: 'Bußgelder im Verkehrsrecht',
      otherFines: 'Bußgelder außerhalb des Verkehrsrechts',
      social: 'Sozialrecht',
      family: 'Familienrecht',
      inheritance: 'Erbrecht',
      consumer: 'Verbraucherrecht',
      others: 'Anderer'
    }
  }
};

export default legalDomainTranslations;
