const vehiclePrivateUseTranslations = {
  en: {
    question: 'Vehicle for private use?',
    questionDescription: 'Select the answer',
    options: {
      yes: 'Yes',
      no: 'No'
    }
  },
  de: {
    question: 'Handelt es sich um ein privates Fahrzeug?',
    questionDescription: 'Bitte wählen Sie die zutreffende Antwort aus.',
    options: {
      yes: 'Ja',
      no: 'Nein'
    }
  }
};

export default vehiclePrivateUseTranslations;
