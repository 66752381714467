import React from 'react';
import Slider from 'react-slick';
import { Translate } from 'react-redux-i18n';

import josef from '../../public/assets/testimonials/josef.jpg';
import eva from '../../public/assets/testimonials/eva.jpg';
import hannelore from '../../public/assets/testimonials/hannelore.jpg';
import reiner from '../../public/assets/testimonials/reiner.jpg';
import ronny from '../../public/assets/testimonials/ronny.jpg';
import sandra from '../../public/assets/testimonials/sandra.jpg';

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div className="container">
      <div className="row homepage-row">
        <div className="col-sm-2" />
        <div className="col-xs-8 col-sm-8 testimonials-container">
          <h2 style={{ textAlign: 'center', padding: '15px 0' }}>
            <Translate value="homePage.testimonials.title" />
          </h2>
          <Slider {...settings}>
            <div className="harms-index-trust-testimonials-entry">
              <img alt="Bastian Schumacher" className="harms-index-trust-testimonials-entry-photo" src={josef} />
              <div className="harms-index-trust-testimonials-entry-text">
                <strong>Danke und großes Lob!</strong>
                <small>— Wasserrohrbruch, Josef&nbsp;M. aus Vilsbiburg</small>
                <p>
                  Als wir nach Haus kamen stand alles unter Wasser, obwohl nur ein kleines Rohr der Spüle kaputt war.
                  Schadenhilfe.online hat super schnell reagiert und der Notfallservice kam noch am gleichen Tag. Wir
                  mussten uns wirklich um nichts kümmern und der Schaden war schneller behoben als wir es gedacht
                  hätten.
                </p>
              </div>
            </div>
            <div className="harms-index-trust-testimonials-entry">
              <img alt="Bastian Schumacher" className="harms-index-trust-testimonials-entry-photo" src={sandra} />
              <div className="harms-index-trust-testimonials-entry-text">
                <strong>Ich lasse jetzt immer die Profis ran!</strong>
                <small>—&nbsp;Kfz-Schaden, Sandra&nbsp;H. aus Köln</small>
                <p>
                  Auf dem Weg zur Arbeit ist mir ein Auto reingefahren und ich hatte zwar alle Daten des Gegners, aber
                  ich hatte kein Zeit mich um alles zu kümmern. Das Team von schadenhilfe.online hat wirklich alles
                  perfekt koordiniert. Der Leihwagen wurde mir sogar nach Hause gebracht, mein Auto wurde in der
                  Werkstatt begutachtet und ich habe es repariert wieder zurückbekommen. Das Beste war allerdings das
                  Schmerzensgeld für mein Schleudertrauma und die 30&nbsp;€ Kostenpauschale, daran hatte ich gar nicht
                  gedacht.
                </p>
              </div>
            </div>
            <div className="harms-index-trust-testimonials-entry">
              <img alt="Bastian Schumacher" className="harms-index-trust-testimonials-entry-photo" src={hannelore} />
              <div className="harms-index-trust-testimonials-entry-text">
                <strong>Gewusst wie, danke!</strong>
                <small>—&nbsp;Haftpflichtschaden, Hannelore&nbsp;K. aus Monsheim</small>
                <p>
                  Mein 5-jähriger Enkel Leon hat mir meine Gleitsichtbrille kaputt gemacht, als ich auf ihn aufpassen
                  musste. Leider kostet eine Neue knapp 800&nbsp;€ und meine Krankenkasse zahlt fast nichts davon. Das
                  Callcenter meiner Haftpflichtversicherung hat leider gleich alles abgelehnt. Erst der nette Mann von
                  schadenhilfe.online hat mich darauf hingewiesen, dass vielleicht über die Versicherung meiner Tochter
                  eine Erstattung möglich wäre. Er hat sich dann um alles gekümmert und nun habe ich schon meine neue
                  Brille.
                </p>
              </div>
            </div>
            <div className="harms-index-trust-testimonials-entry">
              <img alt="Bastian Schumacher" className="harms-index-trust-testimonials-entry-photo" src={ronny} />
              <div className="harms-index-trust-testimonials-entry-text">
                <strong>5 Sterne, weiter so!</strong>
                <small>—&nbsp;Einbruch, Ronny&nbsp;T. aus Pregnitz</small>
                <p>
                  Wir kamen aus dem Urlaub und alles sah normal aus, aber drinnen war alles durcheinander und aus den
                  Schränken gerissen. Die Einbrecher kamen über die Terrassentür ins Haus. Zuerst waren wir geschockt
                  und wussten nicht weiter, dann haben wir schadenhilfe.online kontaktiert und nachdem wir etwas
                  beruhigt wurden, ging alles sehr schnell und einfach. Gerade bei der Schadenaufstellung hätten wir
                  ohne Hilfe sicher sehr viel vergessen.
                </p>
              </div>
            </div>
            <div className="harms-index-trust-testimonials-entry">
              <img alt="Bastian Schumacher" className="harms-index-trust-testimonials-entry-photo" src={reiner} />
              <div className="harms-index-trust-testimonials-entry-text">
                <strong>Freie Fahrt voraus!</strong>
                <small>—&nbsp;Führerscheinentzug, Reiner&nbsp;W. aus Dortmund</small>
                <p>
                  Natürlich hatte ich als Kurierfahrer den ein oder anderen Punkt in Flensburg, aber plötzlich sollte
                  ich meinen Führerschein abgeben. Über schadenhilfe.online habe ich einen super Rechtsanwalt bekommen,
                  die Deckungszusage meiner Rechtsschutz haben die gleich mit eingeholt und irgendwie hat er den letzten
                  Blitzer wegbekommen.
                </p>
              </div>
            </div>
            <div className="harms-index-trust-testimonials-entry">
              <img alt="Bastian Schumacher" className="harms-index-trust-testimonials-entry-photo" src={eva} />
              <div className="harms-index-trust-testimonials-entry-text">
                <strong>
                  Am Ende bekam ich auch noch Geld von meiner Unfallversicherung, das hätte ich vergessen!
                </strong>
                <small>—&nbsp;Kreuzbandriss, Eva&nbsp;M. aus Hamburg</small>
                <p>
                  Beim Skifahren habe ich mir mein Knie verdreht und musste mit der Bergrettung ins Tal. Ich hatte zwar
                  eine Reisekrankenversicherung, aber wusste nicht mehr als die Gesellschaft. Schadenhilfe.online hat
                  das gereicht und die haben sich um alles vor Ort gekümmert und die Kostenzusagen meiner Versicherung
                  besorgt.
                </p>
              </div>
            </div>
          </Slider>
        </div>
        <div className="col-sm-2" />
      </div>
    </div>
  );
};

export default Testimonials;
