const mandateStepTranslations = {
  en: {
    topTitle: 'Great work so far. We are almost done. We just need one more thing',
    description:
      'In order for us to do the rest for you, we need a mandate for it. We do this for free through to WeFox, our legitimation partner.',
    agreeCheckbox: 'I agree to the {{terms}} and {{dataPolicy}}',
    agreeCheckboxError: 'You must agree to the terms and conditions',
    terms: 'Terms and Conditions',
    dataPolicty: 'Data Policy',
    button: 'Give mandate via wefox',
    descriptionNote:
      "You will be redirected to WeFox to finish the process. When you're done, you'll receive a written confirmation and we will get in touch with you via email or phone.",
    descriptionFreeService: 'This service is completely free for you thanks to our partener agreements.',
    goBack: 'Go back to pay selection',
    clientAgreeCheckbox: '{{dataPolicy}} vorgelesen und bestätigt.'
  },
  de: {
    topTitle: 'Danke für die Angaben, die Schadensmeldung ist nun fast fertig. Ein letzter Schritt steht noch aus:',
    description:
      'Damit unser Partner Ihnen schon in dieser Schadensache helfen kann, benötigt er aus rechtlichen Gründen Ihre Zustimmung zur Betreuung.  Über Hellosign geht das ganz einfach online, schnell ohne lästiges Drucken oder Porto.',
    agreeCheckbox:
      'Ich stimme den {{terms}} zu und habe die {{dataPolicy}} zu Kenntnis genommen. Wir geben Ihre Daten nicht unbefugt an Dritte weiter. Ihre Werbeeinwilligung bleibt gültig, bis Sie diese widerrufen. Der Nutzung Ihrer Daten für Werbezwecke können Sie jederzeit ohne Angabe von Gründen widersprechen, z.B. per E-Mail an info@schadenhilfe.online.',
    agreeCheckboxError: 'Sie müssen den allgemeinen Geschäftsbedingungen und der Datenschutzerklärung zustimmen.',
    terms: 'Nutzungsbedingungen',
    dataPolicty: 'Datenschutzerklärung',
    button: 'Legitimation über wefox',
    descriptionNote:
      'Sie werden nun an Wefox weitergeleitet, um den Prozess abzuschließen. Im Anschluß erhalten Sie eine schriftliche Bestätigung, und wir kontaktieren Sie telefonisch oder per Email.',
    descriptionFreeService: 'Dieser Dienst über unseren Vertragspartner ist für Sie komplett kostenlos.',
    goBack: 'Zurück zur vorherigen Seite',
    clientAgreeCheckbox: '{{dataPolicy}} vorgelesen und bestätigt.'
  }
};

export default mandateStepTranslations;
