const payStep = {
  en: {
    navigationTitle: 'Select if you go free or you pay',
    topTitle:
      'Thank for submitting your claim. In order for us to process it, you have the choice between the following 2 options',
    freeTitle: 'Free service',
    freeDescription:
      'You can use this service for free if you let us check at least one of your insurance contracts for you.',
    freeButton: 'I use the free option',
    payTitle: 'Paid service',
    payDescription: 'You don’t to change anything to your insurance contracts. A 29€ fee will occur.',
    payButton: 'I use the paid option'
  },
  de: {
    navigationTitle: 'Super, fast fertig! Wir brauchen nur noch eine Entscheidung.',
    topTitle: 'Danke für die Angaben. Für die Weiterleitung können Sie zwischen zwei Varianten wählen.',
    freeTitle: 'Kostenfrei über Partner',
    freeDescription:
      'Unser Service ist kostenfrei, wenn Sie mindestens einen Versicherungsvertrag künftig von unserem Partner betreuen lassen. Am Vertrag ändert sich weder Beitrag, noch Leistung und die zusätzliche Betreuung ist natürlich auch kostenlos.',
    freeButton: 'Kostenlos melden',
    payTitle: 'Über Einmalzahlung',
    payDescription:
      'Sie wollen keine Unterstützung über unseren Partner und unser Service kostet Sie einmalig 29 €, keine versteckten Kosten oder Abos.',
    payButton: 'Für 29€ melden'
  }
};

export default payStep;
