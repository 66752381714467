const carInitialState = {
  accidentType: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    selectedIds: []
  },
  whatWasDamaged: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    selectedIds: []
  },
  vehicleForPrivateUse: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    answer: null
  },
  vehicleType: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    selectedIds: []
  },
  vehicleRegistrationDriver: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    driverDetails: null,
    licensePlateArray: [],
    licensePlateValue: null,
    /**
     * isDriver is true if the one who writes the claim says he's the driver else a form to extract the name of the driver appears
     */
    isDriver: null
  },
  accidentCircumstances: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    selectedIds: []
  },
  accidentDate: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    selectedTime: null
  },
  accidentLocation: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    address: null,
    city: null,
    country: null
  },
  damagesToReport: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    selectedIds: []
  },
  carVisualDamage: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    selectedParts: [],
    extraDescription: ''
  },
  damageEstimate: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: ''
  },
  currentVehicleState: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    vehicleRepaired: null,
    vehicleDrivable: null
  },
  witnesses: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    hasWitnesses: null,
    witnesses: []
  },
  policeReport: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    hasPoliceReport: null,
    reportText: ''
  },
  drugTest: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    testTaken: null,
    testPositive: null
  },
  thirdPartyVehiclesDamage: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    /**
     * Next property will contain infomation about damaged vehicles: type, numberPlate,
     * contact details of the driver and damages description
     */
    vehicles: []
  },
  thirdPartyPersonsDamage: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    /**
     * Next property will contain infomation about damaged persons
     */
    persons: []
  },
  thirdPartyGoodsDamage: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: ''
  },
  accidentResponsability: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    selectedIds: [],
    description: ''
  },
  animalCause: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    selectedIds: []
  },
  animalAccidentCircumstances: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: ''
  },
  naturalCause: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    selectedIds: []
  },
  natureCircumstances: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: ''
  },
  explosionlCause: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    selectedIds: []
  },
  explosionCircumstances: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: ''
  },
  theftCause: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    selectedIds: []
  },
  theftParts: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: ''
  },
  culprit: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    culpritDetails: null,
    culpritKnown: null
  },
  vandalismCircumstances: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: ''
  },
  notes: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: ''
  },
  suggestLawyer: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    answer: null
  },
  suggestDamageSurveyor: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    answer: null
  },
  lastEditTime: null
};

export default carInitialState;
