const othersInitialState = {
  damagedItems: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    selectedIds: []
  },
  damageCircumstances: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: ''
  },
  propertyOwner: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    culpritDetails: null,
    culpritKnown: null
  },
  culprit: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    culpritDetails: null,
    culpritKnown: null
  },
  accidentDate: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    selectedTime: null
  },
  accidentLocation: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    address: null,
    city: null,
    country: null
  },
  damageEstimate: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: ''
  },
  policeReport: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    hasPoliceReport: null,
    reportText: ''
  },
  notes: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: ''
  },
  suggestLawyer: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    answer: null
  },
  suggestDamageSurveyor: {
    blockDisplayed: false,
    editMode: false,
    editTime: null,
    selectedValue: '',
    answer: null
  },
  lastEditTime: null
};

export default othersInitialState;
