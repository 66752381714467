const policeReportTranslations = {
  en: {
    question: 'Was there a police report?',
    questionDescription: 'Let us know if the police filed a report and give us some details',
    noReport: 'No police report',
    reportLabel: 'Police report',
    reportPlaceholder: 'Describe the police report'
  },
  de: {
    question: 'Wurde ein Polizeibericht aufgenommen?',
    questionDescription: 'Informationen aus dem Polizeibericht können die Bearbeitung beschleunigen.',
    noReport: 'Kein Polizeibericht',
    reportLabel: 'Details zum Polizeibericht',
    reportPlaceholder: 'Bitte tragen Sie bspw. Aktenzeichen und bearbeitende Dienststelle ein.'
  }
};

export default policeReportTranslations;
