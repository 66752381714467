import React from 'react';

const InsuranceCompanies = () => (
  <div className="container-fluid">
    {/* <div className="row">
      <div
        className="col"
        style={{
          backgroundColor: "white",
          textAlign: "center",
          paddingTop: 10,
          fontSize: "0.9em",
        }}
      >
        Unsere Affiliatepartner:
      </div>
    </div>
    <div className="row insurance-companies">
      <div className="col-2" />
      <div className="col-2">
        <div className="company-item allianz" />
      </div>
      <div className="col-2">
        <div className="company-item axa" />
      </div>
      <div className="col-2">
        <div className="company-item ergo" />
      </div>
      <div className="col-2">
        <div className="company-item hdi" />
      </div>
      <div className="col-2" />
    </div> */}
  </div>
);

export default InsuranceCompanies;
