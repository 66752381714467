const suggestDamageSurveyor = {
  en: {
    question: 'Do you need a damage surveyor?',
    questionDescription: 'Select the answer'
  },
  de: {
    question:
      'Bei Fremdverschulden stehen Ihnen kostenlos Gutachter, Rechtsanwalt oder Mietwagen zu. Können wir helfen?',
    questionDescription: 'Bitte wählen Sie die zutreffende Antwort aus.'
  }
};

export default suggestDamageSurveyor;
