const damagesToReportTranslations = {
  en: {
    question: 'What damages should we report?',
    questionDescription: 'Please select one or more damages',
    options: {
      ownVehicle: 'Damages on my vehicle',
      otherVehicle: "Damages on other party's vehicle",
      person: 'Damage on a third-party person',
      goods: 'Damages on third-party goods'
    }
  },
  de: {
    question: 'Welche Art von Schaden soll gemeldet werden?',
    questionDescription: 'Bitte wählen Sie alle zutreffenden Optionen aus.',
    options: {
      ownVehicle: 'Schäden an meinem Fahrzeug',
      otherVehicle: 'Schäden an einem anderen Fahrzeug',
      person: 'Schäden an einer anderen Person',
      goods: 'Schäden an fremden Eigentum'
    }
  }
};

export default damagesToReportTranslations;
