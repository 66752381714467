const accidentResponsabilityTranslations = {
  en: {
    question: 'Who is responsible for the accident?',
    questionDescription: 'Select all that applies',
    options: {
      me: 'Me',
      other: 'Other party',
      both: 'Both responsible'
    },
    descriptionLabel: 'Why is that?',
    descriptionPlaceholder: "Explanation of who's fault it is",
    selectedDescriptionLabel: 'Accident description'
  },
  de: {
    question: 'Wer hat den Unfall verantwortet?',
    questionDescription: 'Bitte alle zutreffenden Angaben auswählen',
    options: {
      me: 'Ich selbst',
      other: 'Jemand anders',
      both: 'Beide'
    },
    descriptionLabel: 'Aus welchem Grund?',
    descriptionPlaceholder: 'Erklärung zur Schuldfrage',
    selectedDescriptionLabel: 'Beschreibung des Unfalls'
  }
};

export default accidentResponsabilityTranslations;
