const vehicleRegistrationDriverTranslations = {
  en: {
    question: 'Were you the driver of the car?',
    questionDescription: 'Select what applies to you',
    // the following 2 items are used when the component is in "just license plate" mode, without the question if the user was or not the driver
    questionJustLicensePlate: "What's your vehicle's license plate?",
    questionDescriptionJustLicensePlate: 'Please type the registration number to continue',
    options: {
      yes: 'Yes',
      no: 'No'
    },
    licensePlateLabel: 'Please enter your license plate number',
    driverDetails: 'Please enter the name of the driver',
    selectedValues: 'Driver: {{name}}, license plate number: {{licensePlate}}',
    invalidLicensePlate: 'License plate is invald',
    vehicleModel: 'Model',
    vehicleManufacturer: 'Manufacturer',
    placeholderVehicleModel: 'Please enter the vehicle model',
    placeholderVehicleManufacturer: 'Please enter the vehicle manufacturer',
    placeholderVehicleKms: "Please enter the vehicle's kms",
    placeholderVehicleConstructionYear: 'Please enter the vehicle construction year',
    vehicleKms: 'Kms',
    vehicleConstructionYear: 'Year'
  },
  de: {
    question: 'Sind Sie das Fahrzeug gefahren?',
    questionDescription: 'Bitte wählen Sie die zutreffende Antwort aus.',
    // the following 2 items are used when the component is in "just license plate" mode, without the question if the user was or not the driver
    questionJustLicensePlate: 'Wie lautet das Autokennzeichen?',
    questionDescriptionJustLicensePlate: 'Bitte Autokennzeichen angeben',
    options: {
      yes: 'Ja',
      no: 'Nein'
    },
    licensePlateLabel: 'Wie lautet das Kennzeichen des beschädigten Fahrzeugs?',
    driverDetails: 'Bitte den Namen des Fahrers / der Fahrerin angeben',
    selectedValues: 'Fahrer/in: {{name}}, Autokennzeichen: {{licensePlate}}',
    invalidLicensePlate: 'Kennzeichen ist ungültig',
    vehicleModel: 'Modell (wenn zur Hand)',
    vehicleManufacturer: 'Hersteller',
    placeholderVehicleModel: 'Bspw. Golf, 320i, Q2',
    placeholderVehicleManufacturer: 'Bspw. VW, Toyota, BMW',
    placeholderVehicleKms: 'Gern auch geschätzt, bspw. 45000',
    placeholderVehicleConstructionYear: 'Bspw. 05/2000',
    vehicleKms: 'Kilometerstand (wenn zur Hand)',
    vehicleConstructionYear: 'Erstzulassung (wenn zur Hand)'
  }
};

export default vehicleRegistrationDriverTranslations;
