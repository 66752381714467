import React, { Component } from 'react';
import Footer from '../common/footer';
import Header from '../common/header';
import HomeBottomForm from './bottom-form';
import Faq from './faq';
import InsuranceCompanies from './insurance-companies';
import ProcessDescription from './process';
import Testimonials from './testimonials';
// import HomeTopForm from './top-form';
import WhyUse from './why-use';
import GDPRModal from '../gdpr-modal';

class Home extends Component {
  render() {
    return (
      <div>
        <Header />
        <main className="homepage-container">
          {/* <HomeTopForm /> */}
          <InsuranceCompanies />
          <ProcessDescription />
          <WhyUse />
          <Testimonials />
          <div className="homepage-horizontal-line" />
          <Faq />
          <HomeBottomForm />
        </main>
        <Footer />
        <GDPRModal />
      </div>
    );
  }
}

export default Home;
