import React, { Component } from 'react';
import Footer from '../common/footer';
import Header from '../common/header';

class DataProtection extends Component {
  render() {
    return (
      <div>
        <Header />

        <article className="legal-privacy-policy">
          <h1>Datenschutzerklärung der Noxalib GmbH</h1>

          <p>
            Wir freuen uns über Ihren Besuch auf unserer Webseite und dem Interesse an unserem Online-Angebot. Da die
            Eingabe persönlicher Daten viel Vertrauen voraussetzt, nehmen wir den Schutz ihren Daten sehr ernst.
            Nachfolgend möchten wir Sie über den Umgang mit Ihren Daten gemäß Artikel 13 EU-Datenschutzgrundverordnung
            (DSGVO) informieren.{' '}
          </p>
          <p>
            Verantwortlicher Verantwortlich für die nachfolgend dargestellte Datenverarbeitung ist die Noxalib GmbH,
            Zielstattstr. 19, 81379 München, Telefon: +4989416177088, E-Mail: info@schadenhilfe.online
          </p>
          <h2>I. Datenerhebung bei Besuch unserer Webseite</h2>
          <h3>1. Nutzungsdaten</h3>
          <p>
            Wenn Sie unsere Webseiten besuchen, werden auf unserem Webserver temporär sogenannte Nutzungsdaten zu
            statistischen Zwecken als Protokoll ausgewertet, um die Qualität unserer Webseiten zu verbessern. Dieser
            Datensatz besteht aus
          </p>
          <p>
            • dem Namen und der Adresse der angeforderten Inhalte, <br />• dem Datum und der Uhrzeit der Abfrage, <br />
            • der übertragenen Datenmenge, <br />• dem Zugriffsstatus (Inhalt übertragen, Inhalt nicht gefunden), <br />
            • der Beschreibung des verwendeten Webbrowsers und des Betriebssystems, <br />• dem Referral-Link, der
            angibt, von welcher Seite Sie auf unsere gelangt sind, <br />• der IP-Adresse des anfragenden Rechners, die
            so verkürzt wird, dass ein Personenbezug nicht mehr herstellbar ist.
          </p>
          <p>
            Die genannten Protokolldaten werden nur in anonymisierter Form ausgewertet. Es erfolgt keine Weitergabe
            dieser Daten an Dritte.
          </p>
          <h3>2. Speicherung der IP-Adresse zu Sicherheitszwecken</h3>
          <p>
            Darüber hinaus speichern wir die von Ihrem Webbrowser übermittelte vollständige IP-Adresse streng
            zweckgebunden für die Dauer von sieben Tagen, in dem Interesse, Angriffe auf unsere Webseiten erkennen,
            eingrenzen und beseitigen zu können. Nach Ablauf dieser Zeitspanne löschen bzw. anonymisieren wir die
            IP-Adresse. Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO und somit das berechtigte Interesse von
            Noxalib.
          </p>
          <h3>3. Erforderliche Cookies</h3>
          <p>
            Auf unseren Webseiten setzen wir Cookies ein, die zur Nutzung unseren Webseiten erforderlich sind. Cookies
            sind kleine Textdateien, die auf Ihrem Endgerät gespeichert und ausgelesen werden können. Man unterscheidet
            zwischen Session-Cookies, die wieder gelöscht werden, sobald Sie ihren Browser schließen und permanenten
            Cookies, die über die einzelne Sitzung hinaus gespeichert werden. Wir nutzen diese erforderlichen Cookies
            nicht für Analyse-, Tracking- oder Werbezwecke. Teilweise enthalten diese Cookies lediglich Informationen zu
            bestimmten Einstellungen und sind nicht personenbeziehbar. Sie können auch notwendig sein, um die
            Benutzerführung, Sicherheit und Umsetzung der Seite zu ermöglichen. Wir nutzen diese Cookies auf Grundlage
            von Art. 6 Abs. 1 S. 1 lit. f DSGVO und somit dem berechtigten Interesse von Noxalib an der Funktionalität
            der Webseite. Sie haben das Recht der Datenverarbeitung zu widersprechen. Bitten verwenden Sie hierfür die
            folgende E-Mail-Adresse: info@schadenhilfe.online. Sie können Ihren Browser so einstellen, dass er Sie über
            die Platzierung von Cookies informiert. Wenn Sie den Einsatz von Cookies insgesamt nicht wünschen, können
            Sie auch deren Speicherung ggf. durch entsprechende Einstellungen Ihres Endgeräts verhindern. Gespeicherte
            Cookies können Sie in den Systemeinstellungen Ihres Endgeräts jederzeit löschen. Bitte beachten Sie, dass
            die Blockierung bestimmter Arten von Cookies zu einer beeinträchtigten Nutzung unseres Online-Angebots
            führen kann.
          </p>
          <h3>4. Einsatz von Google Analytics</h3>
          <p>
            Zur bedarfsgerechten Gestaltung unserer Webseiten verwenden wir das Webanalysetool „Google Analytics“.
            Google Analytics erstellt Nutzungsprofile auf Grundlage von Pseudonymen. Hierzu werden permanente Cookies
            auf Ihrem Endgerät gespeichert und von uns ausgelesen. Auf diese Weise sind wir in der Lage, wiederkehrende
            Besucher zu erkennen und als solche zu zählen. Im Rahmen von Google Analytics unterstützen uns Google
            Ireland Limited und Google LLC. (USA) als Auftragsverarbeiter nach Art. 28 DSGVO. Die Datenverarbeitung kann
            daher auch außerhalb der EU bzw. des EWR stattfinden, wodurch kein angemessenes Datenschutzniveau
            gewährleistet werden kann. Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung gemäß Art.6 Abs.1
            S.1 lit. a DSGVO, sofern Sie Ihre Einwilligung über unser Consent-Banner abgegeben haben. Ihre Einwilligung
            ist freiwillig und kann jederzeit ohne Angabe von Gründen frei mit Wirkung für die Zukunft gemäß Art. 7 Abs.
            3 S. 1 DSGVO widerrufen werden. Bitten verwenden Sie hierfür die folgende E-Mail-Adresse:
            info@schadenhilfe.online
          </p>
          <p>Wir übermitteln Ihre Daten im Rahmen der Nutzung von „Google Analytics“ an nachfolgenden Empfänger:</p>
          <p>
            • Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, <br />• Google LLC, 1600
            Amphitheatre Parkway, Mountain View, California 94043, USA.
          </p>
          <p>
            Auf eine weitere Datenverarbeitung durch den Drittanbieter haben wir grundsätzlich keinen Einfluss. Weitere
            Informationen zum Umgang mit personenbezogenen Daten durch Google finden Sie unter
            https://policies.google.com/privacy?hl=de
          </p>
          <h3>5. Einsatz des Google Tag Managers</h3>
          <p>
            Diese Webseite nutzt den Google Tag Manager. Hierbei handelt es sich um ein Tag-Management-System (TMS),
            dass uns die Einbindung und Verwaltung weiterer Webseiteninhalte in JavaScript- oder HTML-Code ermöglicht.
            Insbesondere können darüber sog. Tags in unsere Webseite integriert und verwaltet werden. Tags sind kleine
            Code-Fragmente oder Markierungen (Web-Beacons, Tracking-Pixel oder ähnliche Markierungen), die Dienste zur
            Webseitenanalyse bzw. zum Nutzer-Tracking eine Unterscheidung bzw. Identifizierung von Nutzern ermöglichen.
          </p>
          <p>
            Die Analyse der Webseitenbesuche bzw. das Nutzertracking erfolgen dabei nicht durch den Google Tag Manager,
            sondern durch die zu diesen Zwecken eingesetzten Dienste, wie beispielsweise Google Analytics oder anderen
            Drittanbieterlösungen. Vielmehr dient der Google Tagmanager lediglich der Einbindung und Verwaltung der für
            die Analyse oder das Tracking nötigen Markierungen in unsere Webseite.
          </p>
          <p>
            Da der Google Tag Manager durch Google bereitgestellt und beim Seitanaufruf von dessen Servern nachgeladen
            wird, werden dabei auch die zum Seitenaufruf technisch erforderlichen Nutzungsdaten übermittelt. Google
            erhält insoweit auch Ihre IP-Adresse, die technisch zum Abruf der Inhalte erforderlich ist.
          </p>
          <p>
            Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO, sofern
            Sie Ihre Einwilligung über unser Consent-Banner abgegeben haben. Ihre Einwilligung ist freiwillig und kann
            jederzeit ohne Angabe von Gründen frei mit Wirkung für die Zukunft gemäß Art. 7 Abs. 3 S. 1 DSGVO widerrufen
            werden. Für den Widerruf verwenden Sie bitte die folgende E-Mail-Adresse: info@schadenhilfe.online
          </p>
          <p>
            Die Datenverarbeitung kann auch außerhalb der EU bzw. des EWR in Drittstaaten ohne angemessenes
            Datenschutzniveau, insbesondere in den USA, erfolgen. Bei einer Übermittlung Ihrer Daten in Drittstaaten
            besteht das Risiko, dass dortige Behörden auf Ihre Daten zu Sicherheits- und Überwachungszwecken zugreifen,
            ohne dass Sie hierüber informiert werden oder Rechtsmittel einlegen können.
          </p>
          <p>Wir übermitteln Ihre Daten im Rahmen der Nutzung des „Google Tag Managers“ an nachfolgenden Empfänger:</p>
          <p>
            • Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, <br />• Google LLC, 1600
            Amphitheatre Parkway, Mountain View, California 94043, USA. Auf eine weitere Datenverarbeitung durch den
            Drittanbieter haben wir grundsätzlich keinen Einfluss.
          </p>
          <p>
            Weitere Informationen zum Umgang mit personenbezogenen Daten durch Google finden Sie unter
            https://policies.google.com/privacy?hl=de
          </p>
          <p>
            Weitere Informationen zum Umgang mit personenbezogenen Daten durch Google finden Sie unter
            https://policies.google.com/privacy?hl=de
          </p>
          <h3>6. Google Webfonts</h3>
          <p>
            Um unseren Inhalt browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf
            dieser Internetseite Google Webfonts. Google Webfonts werden zur Vermeidung mehrfachen Ladens in den Cache
            Ihres Browsers übertragen. Falls der Browser die Google Webfonts nicht unterstützt oder den Zugriff
            unterbindet, werden Inhalte in einer Standardschrift angezeigt.
          </p>
          <p>Auf eine weitere Datenverarbeitung durch den Drittanbieter haben wir grundsätzlich keinen Einfluss.</p>
          <p>
            Weitere Informationen zum Umgang mit personenbezogenen Daten durch Google finden Sie unter
            https://policies.google.com/privacy?hl=de
          </p>
          <h3>7. Trackingtechnologien von Drittanbietern zu Werbezwecken</h3>
          <p>
            Wir nutzen geräteübergreifende Trackingtechnologien, damit Ihnen basierend auf Ihrem Besuch unserer
            Webseiten zielgerichtete Werbung auf anderen Internetseiten angezeigt werden kann und wir erkennen können,
            wie wirksam unsere Werbemaßnahmen waren. Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung,
            sofern Sie Ihre Einwilligung über unser Consent-Banner abgegeben haben. Ihre Einwilligung ist freiwillig und
            kann jederzeit ohne Angabe von Gründen frei mit Wirkung für die Zukunft gemäß Art. 7 Abs. 3 S. 1 DSGVO
            widerrufen werden. Für den Widerruf verwenden Sie bitte die folgende E-Mail-Adresse:
            info@schadenhilfe.online
          </p>
          <p>Wie funktioniert das Tracking?</p>
          <p>
            Wenn Sie unsere Webseiten besuchen, ist es möglich, dass die unten genannten Drittanbieter
            Wiedererkennungsmerkmale für Ihren Browser oder Ihr Endgerät abrufen (z. B. einen sog. Browser-Fingerprint),
            Ihre IP-Adresse auswerten, Wiedererkennungsmerkmale auf Ihrem Endgerät speichern bzw. auslesen (z.B.
            Cookies) oder Zugriff auf individuelle Tracking-Pixel erhalten. Die einzelnen Merkmale können von den
            Drittanbietern genutzt werden, um Ihr Endgerät auf anderen Internetseiten wiederzuerkennen. Wir können bei
            den entsprechenden Drittanbietern die Schaltung von Werbung beauftragen, die sich nach den bei uns besuchten
            Seiten richtet.
          </p>
          <p>
            Was bedeutet geräteübergreifendes Tracking? Sofern Sie sich mit eigenen Benutzerdaten beim Drittanbieter
            anmelden, können die jeweiligen Wiedererkennungsmerkmale verschiedener Browser und Endgeräte miteinander
            verknüpft werden. Wenn der Drittanbieter also z.B. je ein eigenes Merkmal für den von Ihnen verwendeten
            Laptop, Desktop-PC oder das von Ihnen verwendete Smartphone bzw. Tablet erstellt hat, können diese einzelnen
            Merkmale einander zugeordnet werden, sobald Sie mit Ihren Anmeldedaten einen Dienst des Drittanbieters
            nutzen. Auf diese Weise kann der Drittanbieter unsere Werbekampagnen auch über verschiedene Endgeräte hinweg
            zielgerichtet steuern.
          </p>
          <p>Welche Drittanbieter nutzen wir in diesem Rahmen?</p>
          <p>
            Nachfolgend nennen wir Ihnen die Drittanbieter, mit denen wir zu Werbezwecken zusammenarbeiten. Zur
            Absicherung des Datenschutzniveaus wurden mit den Dienstleistern Standardvertragsklauseln abgeschlossen.
            Sofern die Daten in diesem Rahmen außerhalb der EU bzw. des EWR verarbeitet werden, beachten Sie bitte, dass
            dennoch das Risiko besteht, dass Behörden auf die Daten zu Sicherheits- und Überwachungszwecken zugreifen,
            ohne dass Sie hierüber informiert werden oder Rechtsmittel einlegen können. Ein angemessenes
            Datenschutzniveau kann daher nicht gewährleistet werden.{' '}
          </p>
          <p>
            - Google AdSense (Google LLC) - Google Remarketing (Google LLC) - Google AdWords - Bing Ads Universal Event
            Tracking. Wir übermitteln Ihre Daten im Rahmen der Nutzung der aufgeführten Cookies an nachfolgenden
            Empfänger: <br />• Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, <br />• Google
            LLC, 1600 Amphitheatre Parkway, Mountain View, California 94043, USA. <br />• Microsoft Corporation, One
            Microsoft Way, Redmond, WA 98052-6399, USA Auf eine weitere Datenverarbeitung durch den Drittanbieter haben
            wir grundsätzlich keinen Einfluss.
          </p>
          <p>
            Weitere Informationen zum Umgang mit personenbezogenen Daten durch Google finden Sie unter
            https://policies.google.com/privacy?hl=de
          </p>
          <p>
            Weitere Informationen zu den Nutzungs- und Datenschutzrichtlinien von Microsoft finden sie unter
            https://privacy.microsoft.com/de-DE/privacystatement/
          </p>
          <h3>9. Auftragsverarbeitung:</h3>
          <p>
            Durch uns findet eine Verarbeitung Ihrer personenbezogenen Daten nur zu den in dieser Datenschutzerklärung
            genannten Zwecken und auf Basis der hier jeweils aufgeführten Rechtsgrundlagen statt. Wir übermitteln Ihre
            Daten im Rahmen der Auftragsverarbeitung gem. Art. 28 DSGVO an Dienstleister, die uns beim Betrieb unserer
            Webseite und der damit zusammenhängenden Prozesse unterstützen. Unsere Dienstleister sind uns gegenüber
            streng weisungsgebunden und entsprechend vertraglich verpflichtet. Folgende Dienstleister setzen wir ein:
          </p>
          <p>
            • Digital Ocean (Hosting) <br />• MongoDB Atlas (Datenbankhosting)
          </p>
          <h3>10. Speicherdauer</h3>
          <p>
            Wir löschen ihre personenbezogenen Daten, sobald sie für die vorgenannten Verarbeitungszwecke nicht mehr
            erforderlich sind und keine gesetzlichen Aufbewahrungspflichten einer Löschung entgegenstehen.
          </p>
          <h2>II. Datenverarbeitung zur Nutzung unsere Dienstleistung</h2>
          <h3>1. Absenden der Schadenmeldung</h3>
          <p>
            Um Ihnen den Service der Online-Schadenmeldung anbieten zu können, benötigt Noxalib folgende
            personenbezogenen Daten von Ihnen: <br />• Vorname und Nachname <br />• Postalische Anschrift <br />•
            Telefonnummer <br />• E-Mailadresse <br />• Geburtsdatum <br />• Vertragsspezifische Angaben zu bestehenden
            Versicherungen <br />• Schadenspezifische weitere Angaben
          </p>
          <p>
            Die obig genannten personenbezogenen Angaben sind Pflichtfelder, eine Bearbeitung der Schadenmeldung ist
            ansonsten nicht möglich. Die Verarbeitung Ihrer Daten durch Noxalib, sowie die Weitergabe Ihrer Kundendaten
            zum Zwecke der Meldung des Schadens an Ihre Versicherung erfolgt auf Grundlage von Art. 6 Abs. 1 S.1 lit. b
            DSGVO und somit auf Grundlage einer vertraglichen Verpflichtung aus dem kostenfreien Dienstleistungsvertrag.
          </p>
          <h3>2. Schadenmanager </h3>
          <p>
            Sofern Sie sich für unser optionales Angebot „den Schadenmanager“ entscheiden, setzen wir zur weiteren
            Kundenbetreuung geeignete Kooperationspartner ein, welche sicherstellen, dass Sie umfassend beraten und
            betreut werden. Noxalib wird dann auf Grundlage Ihrer Einwilligung gemäß Art. 6 Abs.1 S.1 lit a DSGVO Ihre
            Daten an die folgenden Partner weiterleiten, welche Sich im Anschluss telefonisch mit Ihnen in Verbindung
            setzen:
          </p>
          <p>
            Allianz Deutschland AG Allianz Kunde und Markt GmbH Carento GmbH (nur bei Kfz-Haftpflichtschäden) Carglass
            GmbH (nur bei Kfz-Glasschäden)
          </p>
          <p>
            Ihre Einwilligung zur Weitergabe der Daten ist jederzeit und ohne Angabe von Gründen für die Zukunft frei
            widerrufbar (Art. 7 Abs. 3 S. 1 DSGVO). Bitte verwenden Sie hierfür die folgende E-Mail-Adresse:
            info@schadenhilfe.online{' '}
          </p>
          <p>
            Wir möchten darauf hinweisen, dass die weitere Bearbeitung des von Ihnen gemeldeten Schadens durch die oben
            genannten Partner, in deren eigenen Verantwortlichkeit geschieht und wir keinen Einfluss darauf haben,
            welche personenbezogenen Daten durch diese erhoben werden. Bei etwaigen Fragen bitten wir Sie sich an diese
            zu wenden.
          </p>
          <h3>3. Kontaktaufnahme per Telefon:</h3>
          <p>
            Sofern Sie allgemeine Fragen zu unserem Service oder zu einer konkreten Schadenmeldung haben, besteht die
            Möglichkeit uns telefonisch zu kontaktieren. Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge
            eines Telefonates erhoben werden (Telefonnummer und Name des Anrufenden), ist Art. 6 Abs. 1 lit. f DSGVO, da
            wir ein Interesse daran haben, Ihre Anfrage möglichst unkompliziert, zeitnah und kundengerecht zu
            beantworten. Ohne die Bereitstellung Ihrer Daten können wir Ihre Anfrage leider nicht bearbeiten.
          </p>
          <p>
            Daten, welche im Zusammenhang mit der Abwicklung eines konkreten Schadensereignisses erhoben werden, werden
            auf Grundlage von Art. 6 Abs.1 S.1 lit. b DSGVO verarbeitet. Ihre Daten werden nur Beantwortung Ihrer
            Anfrage verarbeitet und gelöscht, sobald eine Speicherung nicht länger erforderlich ist und keine
            gesetzlichen oder vertraglichen Aufbewahrungsfristen entgegenstehen. Eine Weitergabe an Dritte erfolgt
            nicht. Sie haben das Recht, der auf Art. 6 Abs. 1 S.1 lit. f DSGVO gestützten Datenverarbeitung zu
            widersprechen (Art. 21 Abs. 1 S.1 DSGVO). Bitte verwenden Sie hierfür die folgende E-Mail-Adresse:
            info@schadenhilfe.online Wir möchten an dieser Stelle nochmals darauf hinweisen, davon abzusehen uns
            persönliche Gesundheitsdaten mitzuteilen.
          </p>
          <h3>4. Kontaktaufnahme per E-Mail</h3>
          <p>
            Alternativ ist eine Kontaktaufnahme mit uns über die bereitgestellte E-Mail-Adresse info@schadenhilfe.online
            möglich. In diesem Fall werden die mit der E-Mail übermittelten personenbezogenen Daten des Nutzers
            gespeichert. Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer Übersendung einer E-Mail
            übermittelt werden, ist auch an dieser Stelle Art. 6 Abs. 1 S.1 lit. f DSGVO, da wir ein Interesse daran
            haben, Ihre Anfrage möglichst unkompliziert, zeitnah und kundengerecht zu beantworten. Ohne die
            Bereitstellung unserer Daten können wir Ihre Anfrage leider nicht bearbeiten. Daten, welche im Zusammenhang
            mit der Abwicklung eines konkreten Schadensereignisses erhoben werden, werden auf Grundlage Art. 6 Abs.1 S.1
            lit. b DSGVO verarbeitet. Ihre Daten werden nur zur Beantwortung Ihrer Anfrage verarbeitet und gelöscht,
            sobald eine Speicherung nicht länger erforderlich ist und keine gesetzlichen oder vertraglichen
            Aufbewahrungsfristen entgegenstehen. Eine Weitergabe an Dritte erfolgt nicht. Sie haben das Recht, der auf
            Art. 6 Abs. 1 S.1 lit. f DSGVO gestützten Datenverarbeitung zu widersprechen (Art. 21 Abs. 1 S.1 DSGVO).
            Bitte verwenden Sie hierfür die folgende E-Mail-Adresse: info@schadenhilfe.online Wir möchten auch an dieser
            Stelle nochmals darauf hinweisen, davon abzusehen uns persönliche Gesundheitsdaten mitzuteilen.
          </p>
          <h3>5. Auftragsverarbeitung</h3>
          <p>
            Durch uns findet eine Verarbeitung Ihrer personenbezogenen Daten nur zu den in dieser Datenschutzerklärung
            genannten Zwecken und auf Basis der hier jeweils aufgeführten Rechtsgrundlagen statt. Wir übermitteln Ihre
            Daten im Rahmen der Auftragsverarbeitung gem. Art. 28 DSGVO an Dienstleister, die uns bei der Abwicklung
            ihrer Schadensfälle helfen und die damit zusammenhängenden Prozesse unterstützen. Unsere Dienstleister sind
            uns gegenüber streng weisungsgebunden und entsprechend vertraglich verpflichtet. Folgende Dienstleister
            setzen wir ein:{' '}
            <p>
              • Mailjet (E-Mail-Versand) <br />• Textcontrol (PDF-Erzeugung)
            </p>
            Soweit es sich um Dienstleister handelt, welche ihren Sitz außerhalb der EU bzw. des EWR haben, kann die
            Datenverarbeitung auch in einem Drittland stattfinden. Wir möchten Sie auf das Risiko hinweisen, dass
            dortige Behörden auf Ihre Daten zu Sicherheits- und Überwachungszwecken zugreifen können, ohne dass sie
            hierüber informiert werden oder Rechtsmittel einlegen können. Ein angemessenes Datenschutzniveau kann in
            diesen Fällen nicht gewährleistet werden.
          </p>
          <h3>6. Speicherdauer</h3>
          <p>
            Wir löschen Ihre Daten, sobald wir Ihren Schadenfall abgewickelt haben und sofern keine gesetzlichen
            Aufbewahrungspflichten entgegenstehen.
          </p>
          <h3>7. SSL-Verschlüsselung</h3>
          <p>
            Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik
            entsprechende Verschlüsselungsverfahren (z.B. SSL) über HTTPS.
          </p>
          <h2>III. Ihre Rechte als betroffene Person</h2>
          <p>
            Bei der Verarbeitung Ihrer personenbezogenen Daten gewährt die DSGVO Ihnen als betroffene Person bestimmte
            Rechte:
          </p>
          <p>
            Auskunftsrecht (Art. 15 DSGVO) Sie haben das Recht eine Bestätigung darüber zu verlangen, ob Sie betreffende
            personenbezogene Daten verarbeitet werden; ist dies der Fall, so haben Sie ein Recht auf Auskunft über diese
            personenbezogenen Daten und auf die in Art. 15 DSGVO im Einzelnen aufgeführten Informationen.
          </p>
          <p>
            Recht auf Berichtigung (Art. 16 DSGVO) Sie haben das Recht, unverzüglich die Berichtigung Sie betreffender
            unrichtiger personenbezogener Daten und ggf. die Vervollständigung unvollständiger Daten zu verlangen.
          </p>
          <p>
            Recht auf Löschung (Art. 17 DSGVO) Sie haben das Recht, zu verlangen, dass Sie betreffende personenbezogene
            Daten unverzüglich gelöscht werden, sofern einer der in Art. 17 DSGVO im Einzelnen aufgeführten Gründe
            zutrifft.
          </p>
          <p>
            Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO) Sie haben das Recht, die Einschränkung der
            Verarbeitung zu verlangen, wenn eine der in Art. 18 DSGVO aufgeführten Voraussetzungen gegeben ist, z. B.
            wenn Sie Widerspruch gegen die Verarbeitung eingelegt haben, für die Dauer der Prüfung durch den
            Verantwortlichen.
          </p>
          <p>
            Recht auf Datenübertragbarkeit (Art. 20 DSGVO) In bestimmten Fällen, die in Art. 20 DSGVO im Einzelnen
            aufgeführt sind, haben Sie das Recht, die Sie betreffenden personenbezogenen Daten in einem strukturierten,
            gängigen und maschinenlesbaren Format zu erhalten bzw. die Übermittlung dieser Daten an einen Dritten zu
            verlangen.
          </p>
          <p>
            Widerrufsrecht (Art. 7 DSGVO) Sofern die Verarbeitung von Daten auf Grundlage Ihrer Einwilligung erfolgt,
            sind Sie nach Art. 7 Abs. 3 DSGVO berechtigt, die Einwilligung in die Verwendung Ihrer personenbezogenen
            Daten jederzeit zu widerrufen. Bitte kontaktieren Sie uns diesbezüglich mit E-Mail an
            info@schadenhilfe.online. Bitte beachten Sie, dass der Widerruf erst für die Zukunft wirkt. Verarbeitungen,
            die vor dem Widerruf erfolgt sind, sind davon nicht betroffen.
          </p>
          <p>
            Widerspruchsrecht (Art. 21 DSGVO) Werden Daten auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f DSGVO
            (Datenverarbeitung zur Wahrung berechtigter Interessen) oder auf Grundlage von Art. 6 Abs. 1 S. 1 lit. e
            DSGVO (Datenverarbeitung zur Wahrung öffentlichen Interesse oder in Ausübung öffentlicher Gewalt) erhoben,
            steht Ihnen das Recht zu, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die
            Verarbeitung Widerspruch einzulegen. Wir verarbeiten die personenbezogenen Daten dann nicht mehr, es sei
            denn, es liegen nachweisbar zwingende schutzwürdige Gründe für die Verarbeitung vor, die gegenüber Ihren
            Interessen, Rechten und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder
            Verteidigung von Rechtsansprüchen.
          </p>
          <p>
            Beschwerderecht bei einer Aufsichtsbehörde (Art. 77 DSGVO) Sie haben gem. Art. 77 DSGVO das Recht auf
            Beschwerde bei einer Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden
            Daten gegen datenschutzrechtliche Bestimmungen verstößt. Das Beschwerderecht kann insbesondere bei einer
            Aufsichtsbehörde in dem Mitgliedstaat Ihres gewöhnlichen Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts
            des mutmaßlichen Verstoßes geltend gemacht werden.
          </p>
          <p>
            Geltendmachung Ihrer Rechte Sofern vorstehend nichts anderes beschrieben wurde, wenden Sie sich zur
            Geltendmachung Ihrer Betroffenenrechte bitte an die im Impressum genannte Stelle.
          </p>
          <h2>IV. Kontaktdaten des Datenschutzbeauftragten</h2>
          <p>
            Sofern Sie Fragen zum Datenschutz haben, wenden Sie sich gerne an die mit dem Datenschutz beauftragte Person
            in unserem Unternehmen:
          </p>
          <p>Ulrich Rose, Hofmannstr. 30, 81379 München, E-Mail: rose@schadenhilfe.online</p>
          <h2>V. Stand</h2>
          <p>Es gilt die aktuellste Version dieser Datenschutzerklärung. Stand: 31. März 2022.</p>
        </article>

        <Footer />
      </div>
    );
  }
}

export default DataProtection;
