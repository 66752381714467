const carPartsTranslations = {
  en: {
    labels: {
      sectionQuestion: 'Where did your vehicle get damaged?',
      sectionDescription: 'Please mark the damaged areas',
      textareaDescription: 'Here you can describe the damage to the vehicle in more detail.',
      textareaPlaceholder: 'Describe damage'
    },
    bumperFrontLeft: 'Bumper f.l.',
    bumperFrontRight: 'Bumper f.r.',
    bumperReartLeft: 'Bumper r.l.',
    bumperRearRight: 'Bumper r.r.',
    bonnet: 'Bonnet',
    fenderFrontLeft: 'Fender f.l.',
    fenderFrontRight: 'Fender f.r.',
    fenderRearLeft: 'Fender r.l.',
    fenderRearRight: 'Fender r.r.',
    doorFrontLeft: 'Door f.l.',
    doorFrontRight: 'Door f.r.',
    doorRearLeft: 'Door r.l',
    doorRearRight: 'Door r.r',
    roof: 'Roof',
    trunk: 'Trunk',
    windscreen: 'Windscreen',
    rearWindow: 'Rear window'
  },
  de: {
    labels: {
      sectionQuestion: 'Wo wurde Ihr Fahrzeug beschädigt?',
      sectionDescription: 'Bitte klicken Sie auf die beschädigten Stellen am Fahrzeug.',
      textareaDescription: 'Detailbeschreibung (optional)',
      textareaPlaceholder: 'Hier können Sie die Schäden im Detail beschreiben.'
    },
    bumperFrontLeft: 'Stoßstange v.l.',
    bumperFrontRight: 'Stoßstange v.r.',
    bumperReartLeft: 'Stoßstange h.l.',
    bumperRearRight: 'Stoßstange h.r.',
    bonnet: 'Motorhaube',
    fenderFrontLeft: 'Kotflügel v.l.',
    fenderFrontRight: 'Kotflügel v.r.',
    fenderRearLeft: 'Kotflügel h.l.',
    fenderRearRight: 'Kotflügel h.r.',
    doorFrontLeft: 'Tür v.l.',
    doorFrontRight: 'Tür v.r.',
    doorRearLeft: 'Tür h.l.',
    doorRearRight: 'Tür h.r.',
    roof: 'Dach',
    trunk: 'Kofferraum',
    windscreen: 'Frontscheibe',
    rearWindow: 'Heckscheibe'
  }
};

export default carPartsTranslations;
