const globalFormTranslations = {
  en: {
    navigationBar: {
      whatHappened: 'What happened?',
      moreDetails: 'More details about it',
      takeCare: 'We take care of you'
    },
    personalWelcome: 'Hi, {{name}}, can you tell us more about the incident?',
    generalWelcome: 'Hi, can you tell us more about the incident?',
    lastStepTopTitle: 'Last step: contact/insurance details',
    newsletterAgreement: 'I agree to receive newsletter',
    general: {
      yes: 'Yes',
      no: 'No',
      editSelection: 'Change answer'
    },
    generalForms: {
      firstName: 'First name',
      lastName: 'Last name'
    },
    multiSelect: {
      title: 'Multiple selection is possible.',
      selectButton: 'Select',
      changeSelectionCancel: 'Cancel change',
      changeSelectionApply: 'OK, change it'
    },
    damageType: {
      title: 'What was damaged?',
      description: 'Please select the damage type',
      car: 'Car',
      house: 'House/Flat',
      person: 'Person',
      legal: 'Legal dispute',
      others: 'Others (Phone, Glasses, Bike, etc)'
    },
    calendar: {
      dateButton: 'Date',
      timeButton: 'Time',
      hours: 'Hours',
      minutes: 'Minutes'
    },
    changeModal: {
      title: 'Are you sure?',
      message: 'By editing this field, you will lose any information you provided in the steps after this field',
      cancelButton: 'Cancel',
      applyButton: 'I understand and continue'
    },
    damageEstimate: {
      question: 'What is the damage estimate?',
      questionDescription: 'Select the range that you think is correct',
      lastOption: 'Over', // used in 'Over 50.000€'
      placeholder: 'Select approximate damage'
    },
    errorModal: {
      title: 'Oops, thre was an error',
      closeButton: 'Close'
    },
    notesSection: {
      question:
        'Was ist Ihnen bei der Bearbeitung sonst noch wichtig, oder welches Schadendetail wollen Sie uns noch mitteilen?',
      questionDescription: 'Add extra notes',
      placeholder: 'Type your notes here'
    },
    preventLeavingPage: "Are you sure you want to leave this page? You'll lose all the data typed in the form!"
  },
  de: {
    navigationBar: {
      whatHappened: 'Was ist vorgefallen?',
      moreDetails: 'Genauere Angaben',
      takeCare: 'Wir kümmern uns um Sie'
    },
    personalWelcome: 'Hallo, {{name}}, können Sie uns erzählen was passiert ist?',
    generalWelcome: 'Bitte beschreiben Sie den vorgefallenen Schaden.',
    lastStepTopTitle: 'Ihre Kontaktdaten',
    newsletterAgreement: 'Bitte mich für den Newsletter eintragen',
    general: {
      yes: 'Ja',
      no: 'Nein',
      editSelection: 'Antwort ändern'
    },
    generalForms: {
      firstName: 'Vorname',
      lastName: 'Nachname'
    },
    multiSelect: {
      title: 'Mehrfachangaben möglich',
      selectButton: 'Auswählen',
      changeSelectionCancel: 'Abbrechen',
      changeSelectionApply: 'OK, jetzt ändern'
    },
    damageType: {
      title:
        'Herzlich willkommen bei Schadenhilfe.online, der unabhängigen Plattform zur Meldung von Versicherungsschäden in Deutschland – auch für Ihre Versicherung.',
      titleShort: 'Schadenskategorie',
      description: 'Die Schadenmeldung dauert nur wenige Minuten. Bitte wählen Sie zunächst die Art des Schadens aus.',
      car: 'Fahrzeug',
      house: 'Gebäude-/Hausrat',
      person: 'Personen',
      legal: 'Rechtsangelegenheit',
      others: 'Anderer Schaden (Handy, Brille, Fahrrad, u.a.)'
    },
    calendar: {
      dateButton: 'Datum',
      timeButton: 'Uhrzeit',
      hours: 'Stunde',
      minutes: 'Minute'
    },
    changeModal: {
      title: 'Sind Sie sicher?',
      message: 'Wenn diese Angabe geändert wird, gehen ggfs. alle weiteren Angaben dazu verloren.',
      cancelButton: 'Abbrechen',
      applyButton: 'Zustimmen und weiter'
    },
    damageEstimate: {
      question: 'Wie hoch ist die geschätzte Schadenhöhe?',
      questionDescription:
        'Bitte wählen Sie die zutreffende Option aus. Der Schaden wird im späteren Verlauf der Bearbeitung genau bestimmt.',
      lastOption: 'Mehr als', // used in 'Over 50.000€'
      placeholder: 'Bitte wählen Sie die ungefähre Schadenhöhe aus.'
    },
    errorModal: {
      title: 'Es ist ein Fehler aufgetreten',
      closeButton: 'Schließen'
    },
    notesSection: {
      question:
        'Was ist Ihnen bei der Bearbeitung sonst noch wichtig, oder welches Schadendetail wollen Sie uns noch mitteilen?',
      questionDescription: 'Diese Informationen können uns helfen, den Schadensfalls schnellstmöglich zu bearbeiten.',
      placeholder: 'Ihre Notizen'
    },
    preventLeavingPage: 'Sind Sie sicher, dass Sie diese Seite verlassen möchten? Alle eingegeben Daten gehen verloren.'
  }
};

export default globalFormTranslations;
