const accidentTypeTranslations = {
  en: {
    question: 'What kind of accident was it?',
    questionDescription: 'Select one accident type',
    options: {
      withAnotherVehicle: 'Accident with another vehicle',
      withoutOtherVehicle: 'Accident without other vehicle',
      accidentWithAnimals: 'Accident with animals',
      windows: 'Vehicle windows are broken',
      nature: 'Damages due to natural events',
      fire: 'Accident with fire or explosion',
      theft: 'Damages due to theft',
      vandalism: 'Damages due to vandalism'
    }
  },
  de: {
    question: 'Um welche Art von Unfall handelt es sich?',
    questionDescription: 'Bitte wählen Sie die Art des Unfalls aus.',
    options: {
      withAnotherVehicle: 'Unfall mit einem anderen Fahrzeug',
      withoutOtherVehicle: 'Unfall ohne ein anderes Fahrzeug',
      accidentWithAnimals: 'Unfall mit Tieren',
      windows: 'Glasschaden',
      nature: 'Schäden durch Umwelteinflüsse',
      fire: 'Schäden durch Feuer oder Explosion',
      theft: 'Diebstahlschäden',
      vandalism: 'Schäden durch Vandalismus'
    }
  }
};

export default accidentTypeTranslations;
