import React, { Component } from 'react';
import Footer from '../common/footer';
import Header from '../common/header';

class TermsConditions extends Component {
  render() {
    return (
      <div>
        <Header />

        <article className="legal-privacy-policy">
          <h1>Allgemeine Geschäftsbedingungen zur Nutzung des Services SCHADENHILFE.online</h1>
          <h2>1. Verwender</h2>
          <p>
            Verwender dieser Allgemeinen Geschäftsbedingungen ist die Noxalib GmbH (Zielstattstr. 19, 81379 München) als
            Betreiberin der Website schadenhilfe.online (nachstehend: die SCHADENHILFE).
          </p>
          <h2>2. Mitgliedschaft bei der Schadenhilfe</h2>
          <p>
            Die nachfolgenden Bestimmungen regeln das Verhältnis zwischen der Schadenhilfe und dem Kunden. Durch die
            eigenverantwortliche Nutzung des Schadenformulars erwirbt der Kunde die Mitgliedschaft bei der Schadenhilfe
            und damit den Zugriff auf die nachstehend näher beschriebenen Services. Im Bereich der angebotenen Services
            fungiert die Schadenhilfe, soweit dies nicht ausdrücklich im Einzelfall anders gegenüber dem Kunden
            mitgeteilt wird, als Vermittler und Empfehlungsgeber unterschiedlicher Leistungen. Vertragsverhältnisse
            zwischen dem Kunden und einem seitens der Schadenhilfe vermittelten Dienstanbieter und Partner kommen
            ausschließlich zwischen diesen beiden Parteien zustande, die Schadenhilfe ist nicht Partei eines solchen
            Vertragsverhältnisses und haftet insofern nicht für vertragliche Primär- oder Sekundäransprüche zwischen den
            Vertragsparteien.
          </p>
          <h2>3. Weiterleitung von Schadenmeldungen</h2>
          <h3>3.1 Aufnahme der Schadenmeldung</h3>
          <p>
            Soweit der Kunde in einen Versicherungsschaden verwickelt ist und diesen der SCHADENHILFE über die dazu
            vorgesehen Kommunikationswege (Website, Email, Telefon, App und sonstige) meldet, kommt das
            Auftragsverhältnis zur Weitergabe der Schadensmeldung mit der SCHADENHILFE durch die Mitteilung der
            Bearbeitung des Schadenfalles durch die SCHADENHILFE (in der Regel per Email oder Telefon) zustande.
          </p>
          <h3>3.2 Umfang des Leistungsangebotes der SCHADENHILFE</h3>
          <p>
            Nach erklärter Annahme wird die SCHADENHILFE die eigenverantwortlich gemachten Kundenangaben zum Schadenfall
            an die ebenfalls eigenverantwortlich angegebenen Versicherungsgesellschaften weiterleiten. Die Weiterleitung
            erfolgt ungeprüft, ohne jegliche Rechts- oder anderweitiger Beratungsleistung. Es findet lediglich eine
            Plausibilitätsprüfung der eigenverantwortlich vom Kunden gemachten Angaben seitens der Schadenhilfe statt.
          </p>
          <h3>
            3.4 Übermittlung/Weitergabe personenbezogener Daten an Diensteanbieter, Partner und
            Versicherungsgesellschaften
          </h3>
          <p>
            Dem Kunden ist bewusst, dass die Schadenmeldung vorbezeichneten Sinne die Übermittlung personenbezogener
            Daten des Kunden an solche Diensteanbieter, Partner und Versicherungsgesellschaften unabdingbar erforderlich
            macht. Die SCHADENHILFE wird sich bei der Übermittlung personenbezogener Daten an solche Diensteanbieter,
            Partner und Versicherungsgesellschaften auf das erforderliche Maß beschränken.
          </p>
          <h3>3.5 Abwicklung von Zahlungsverkehr</h3>
          <p>
            Die Abwicklung von Versicherungsschäden beinhaltet in der Regel Zahlungsverkehr im Zusammenhang mit
            Ersatzleistungen durch den jeweiligen Versicherer sowie bezüglich der Vergütung der im Rahmen der Abwicklung
            beteiligten Dienstleister und Partner. Soweit mit der Geltendmachung bzw. Regulierung des Verkehrsunfalles
            gegenüber dem Versicherer eine Rechtsanwaltskanzlei beauftragt wird, wird der Kunde dieser eine
            entsprechende gesonderte Geldempfangsvollmacht erteilen. Die beauftragte Rechtsanwaltskanzlei wird sodann
            erhaltene Geldbeträge abzüglich der ihr selbst entstandenen Kosten an die Kunden direkt ausbezahlen. Die
            SCHADENHILFE wird direkt keine Zahlungen aus Versicherungsleistungen entgegennehmen.
          </p>
          <h2>4. Angebote im Rahmen der Mitgliedschaft</h2>
          <p>
            Als Mitglied bei den SCHADENHILFE ist der Kunde berechtigt, auf ein jeweils einzelfallbezogenes Angebot der
            SCHADENHILFE hin weitere bzw. andere Dienste und Empfehlungen in Anspruch zu nehmen, soweit sie für ihn
            sinnvoll sind und er sich im Einzelfall für eine Inanspruchnahme entscheidet. Derartige Angebote beziehen
            sich auf alle notwendigen oder sinnvollen Leistungen für den Kunden (z.B. für laufende Schadenermittlung,
            Schadenbehebung, Beteruung von Versicherungsverträge, besondere Preisangebote, Wartung, Hauptuntersuchung,
            Reifenwechsel, etc.) oder in einem Angebot für weitere Produkte und Dienstleistungen, die der Kunde auf
            Empfehlung bzw. Vermittlung der SCHADENHILFE bei Dritten beziehen kann (z.B. Fahrzeuge, Reifen,
            Schmierstoffe, Zubehörteile, Versicherungsprodukte, Kraftstoffe, Fahrzeugpflege, etc.). Gleichermaßen wird
            die SCHADENHILFE dem Kunden für diesen im weitesten Feld interessante Angebote im Zusammenhang mit einem
            möglichen Erwerb eines neuen Fahrzeuges, Vermarktung und Verkauf seines alten Fahrzeuges sowie zugehöriger
            wirtschaftlicher Dienstleistungen unterbreiten. Der Kunde willigt insofern ein, von der SCHADENHILFE
            derartige Angebote mittels elektronischer Post an seine während der Registrierung hinterlegte bzw. auf
            sonstigen Kommunikationswegen mitgeteilte Emailadresse zu erhalten. Dem Bezug derartiger Angebote durch
            elektronische Post kann der Kunde zu jeder Zeit über Noxalib GmbH, Zielstattstr. 19, 81379 München oder per
            Email über info@schadenhilfe.online widersprechen, ohne dass hierfür andere Kosten entstehen könnten, als
            die Übermittlungskosten nach den Basistarifen.
          </p>
          <h2>5. Kosten</h2>
          <p>
            Die durch die Schadenhilfe gegenüber dem Kunden erbrachten Leistungen sind für den Kunden kostenlos. Es ist
            möglich, dass die SCHADENHILFE im Zusammenhang mit den angebotenen Services von dritter Seite Zuwendungen
            erhalten (z.B. in Form von Vermittlungsprovisionen, Nutzungsentgelten, Lizenzgebühren, Werbekostenzuschüssen
            und ähnlichem). Der Kunde erkennt ausdrücklich an, dass zur Sicherstellung und zum Betrieb des Services der
            SCHADENHILFE sowie zur Gewährleistung einer hohen Servicequalität derartige Zuwendungen notwendig sind. §
            667 BGB wird ausdrücklich abbedungen. Ebenso abbedungen wird der Regelungsgehalt des § 666 BGB, soweit sich
            dies nicht auf Informationen bezieht, die die tatsächliche Abwicklung im Rahmen eines in Anspruch genommenen
            Services betreffen oder für deren Rechenschaftslegung die SCHADENHILFE ausdrücklich eine Pflicht übernommen
            haben.
          </p>
          <h2>6. Pflichten des Kunden</h2>
          <p>
            Der Kunde ist im Rahmen der Mitgliedschaft bei der SCHADENHILFE verpflichtet, seine mit der Registrierung
            angegebenen personenbezogenen und Fahrzeugdaten wahrheitsgemäß abzugeben und stets auf einem aktuellen Stand
            zu halten. Der Kunde ist ferner verpflichtet, den SCHADENHILFE im Zusammenhang mit der Inanspruchnahme von
            Services stets vollständig wahrheitsgetreue Informationen zu unterbreiten. Der Kunde erkennt an, dass
            vollständige und wahrheitsgemäße Informationen zur Durchführung der von der SCHADENHILFE angebotenen
            Services unabdingbar erforderlich sind. Dies betrifft insbesondere für den Fall der Inanspruchnahme des
            Services Schadenabwicklung sämtliche tatsächlichen Informationen zum Schadenhergang und damit in
            Zusammenhang stehende Informationen. Dem Kunden ist bewusst, dass fehlerhafte Informationen insbesondere im
            Zusammenhang mit der Inanspruchnahme des Services Schadenabwicklung unter Umständen zu der Verursachung von
            Kosten an sich oder von erhöhten Kosten durch die Beauftragung von dritten Dienstleistern entstehen können,
            für die der Kunde persönlich haftet.
          </p>
          <h2>7. Haftung des Kunden</h2>
          <p>
            Soweit der Kunde wissentlich oder grob fahrlässig falsche Angaben gegenüber der SCHADENHILFE macht,
            insbesondere im Zusammenhang mit dem tatsächlichen Hergang eines Schadens, haftet er der SCHADENHILFE für
            sämtliche Kosten, die diese verauslagt haben und infolge falscher Angaben des Kunden nicht von dritter Seite
            erstattet erhalten können. Die Haftung des Kunden gegenüber Dritten (z.B. im Zusammenhang mit der Abwicklung
            eines Verkehrsunfalles beauftragten Personen) bleibt von diesen Bestimmungen unberührt und ist Gegenstand
            eines zwischen dem Kunden und einem solchen Dritten zustande gekommenen Vertragsverhältnisses.
          </p>
          <h2>8. Haftung der Schadenhilfe</h2>
          <h3>8.1 Vermittelte Leistungen</h3>
          <p>
            Die SCHADENHILFE werden im Rahmen der von ihnen angebotenen Services ausschließlich als Vermittler und
            Empfehlungsgeber von Leistungen dritter Personen tätig, sie haften daher nicht für wie immer geartete
            Ansprüche oder Schäden, die aus einem Rechtsverhältnis zwischen dem Kunden und solchen dritten Personen
            entstehen. Solche dritten Personen sind nicht Erfüllungsgehilfen der SCHADENHILFE.
          </p>
          <h3>8.2 Eigene Leistungen</h3>
          <p>
            Soweit die SCHADENHILFE im Rahmen der angebotenen Services eigene Leistungen gegenüber dem Kunden erbringen
            ist ihre Haftung grundsätzlich auf Vorsatz und grobe Fahrlässigkeit beschränkt. Dies gilt nicht für Schäden
            an Leben, Körper und Gesundheit, für die Verletzung von Pflichten, die die Durchführung des Services
            überhaupt erst ermöglichen (sog. Kardinalpflichten) sowie für Pflichten, für die SCHADENHILFE eine Garantie
            übernommen haben.
          </p>
          <h2>9. Datenschutz</h2>
          <p>
            Die Erhebung, Verarbeitung und Nutzung personenbezogener Daten durch die SCHADENHILFE erfolgt nach den
            Bestimmungen der Bundesdatenschutzgesetze sowie aufgrund der Bestimmungen der Datenschutzerklärung der
            SCHADENHILFE. Die SCHADENHILFE werden außerhalb notwendiger Übermittlungsvorgänge im Zusammenhang mit der
            Nutzung der Services durch den Kunden keine personenbezogenen Daten ohne gesonderte Einwilligung des Kunden
            weitergeben.
          </p>
          <h2>10. Vertragsbeendigung</h2>
          <p>
            Der Kunde kann jederzeit ohne Angabe von Gründen sein Benutzerkonto bei der SCHADENHILFE auflösen lassen.
            Die schriftliche Mitteilung ist an folgende Adresse zu richten: Noxalib GmbH, Zielstattstr. 19, 81379
            München. Mit Eingang der Mitteilung wird das Nutzerkonto des Kunden innerhalb von 10 Tagen aufgelöst. Eine
            Nutzung der Services der SCHADENHILFE ist ab dem Zeitpunkt des Zugangs des Auflösungswunsches nicht mehr
            möglich. Soweit eine Schadenabwicklung zu diesem Zeitpunkt noch nicht abgeschlossen ist, werden seitens der
            SCHADENHILFE keine weiteren Schritte und Handlungen veranlasst.
          </p>
          <h2>11. Schlussbestimmungen</h2>
          <p>
            Sollte eine der Bestimmungen dieser Allgemeinen Geschäftsbedingungen unwirksam und/oder undurchführbar sein
            oder werden, so bleibt hiervon die Geltung der übrigen Bestimmungen unberührt. Dem Kunden ist bekannt, dass
            die SCHADENHILFE sämtliche Leistungen im Zusammenhang mit der Nutzung der Services vom Sitz der Gesellschaft
            aus unternehmen. Erfüllungsort hinsichtlich sämtlicher Bestandteile der angebotenen Services ist daher
            München. Soweit der Kunde keinen allgemeinen Gerichtsstand innerhalb der Europäischen Union hat, Kaufmann
            oder juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen ist, ist
            Gerichtsstand für sämtliche Streitigkeiten zwischen den SCHADENHILFE und dem Kunden München (Landgericht
            München I). Für die Nutzung der Services gilt ausschließlich deutsches Recht unter Ausschluss des
            UN-Kaufrechts.
          </p>
        </article>

        <Footer />
      </div>
    );
  }
}

export default TermsConditions;
