/**
 * This is the first section of "damages due to natural events"
 */

const natureCircumstancesTranslations = {
  en: {
    question: 'Which natural phenomena damaged your vehicle?',
    questionDescription: 'Please select the option which describes best what happened',
    options: {
      hail: 'Hail',
      storm: 'Storm',
      lightning: 'Lightning',
      flooding: 'Flooding',
      landslide: 'Landslide',
      avalanche: 'Avalance'
    }
  },
  de: {
    question: 'Welcher Umweltschaden ist aufgetreten?',
    questionDescription: 'Bitte wählen Sie die Option, die ab ehesten zutrifft.',
    options: {
      hail: 'Hagel',
      storm: 'Sturm',
      lightning: 'Blitzeinschlag',
      flooding: 'Flut',
      landslide: 'Erdrutsch',
      avalanche: 'Lawine'
    }
  }
};

export default natureCircumstancesTranslations;
