import React from 'react';
import { I18n } from 'react-redux-i18n';
import carglassLogo from '../../public/assets/partners/carglass.svg';
import SVGInline from 'react-svg-inline';

const HomeBottomForm = () => (
  <div className="home-top-form">
    <div className="container-fluid full-width-image bottom-image">
      <div className="container">
        {/* <div className="row">
          <div className="col-12 align-center white-text">
            <h3>{I18n.t('homePage.userForm.readyToStartClaim')}</h3>
          </div>
        </div>
        <div className="row button-row" style={{ paddingBottom: 0 }}>
          <div className="col-12">
            <a href="/meldung">
              <div className="btn btn-lg claim-button">{I18n.t('homePage.userForm.claimButton')}</div>
            </a>
          </div>
        </div> */}

        <div className="container">
          <div className="row">
            <div className="col-12 align-center white-text">
              <h3>{I18n.t('homePage.userForm.ourPartners')}</h3>
            </div>
          </div>
          <div className="row button-row" style={{ paddingBottom: 50 }}>
            <SVGInline className="row button-row" svg={carglassLogo} />
          </div>
        </div>

        {/* <div className="row">
          <div className="col-12 align-center white-text claim-submitted-row">
            <span>{I18n.t("homePage.userForm.claimSubmitted.text")}</span>
            <a href="#">{I18n.t("homePage.userForm.claimSubmitted.button")}</a>
          </div>
        </div> */}
      </div>
    </div>
  </div>
);

export default HomeBottomForm;
