const insuranceDetailsTranslations = {
  en: {
    question: 'Your Insurance situation',
    questionDescription: 'Which insurance company should we let know about this?',
    noCompaniesFound: 'No insurance companies found, please check the settings',
    form: {
      labels: {
        company: 'Your insurance company',
        policyNumber: 'Your policy number',
        newCompany: 'Insurance company name',
        companyNotFound: 'I cannot find my company in the list',
        notPolicyOwnerCheckbox: 'I am not the policy owner',
        newPolicyOwner: 'Give us the details of the policy owner'
      },
      placeholders: {
        company: 'Select an insurance company',
        newCompany: 'Type the name of your insurance company',
        policyNumber: 'Type your policy number',
        policyOwnerFirstName: "Type Policy owner's first name",
        policyOwnerLastName: "Type Policy owner's last name",
        policyOwnerEmail: "Type Policy owner's email",
        policyOwnerPhone: "Type Policy owner's phone",
        policyOwnerCity: "Type Policy owner's city",
        policyOwnerAddress: "Type Policy owner's address"
      },
      errors: {
        company: 'Insurance company is required',
        policyNumber: 'Policy number is required',
        policyOwnerDetailsRequired: 'Policy owner details are required'
      }
    }
  },
  de: {
    question: 'Ihre Versicherung',
    questionDescription:
      'An welche Versicherung dürfen wir Ihre Schadenmeldung übermitteln? Dieser Service ist für Sie kostenfrei.',
    noCompaniesFound: 'Keine Versicherungsgesellschaften gefunden, bitte überprüfen Sie die Einstellungen',
    form: {
      labels: {
        company: 'Ihre Versicherungsgesellschaft',
        policyNumber: 'Ihre Versicherungsnummer',
        newCompany: 'Name der Versicherungsgesellschaft',
        companyNotFound: 'Versicherungsgesellschaft nicht aufgeführt',
        notPolicyOwnerCheckbox: 'Ich bin nicht der Versicherungsnehmer',
        newPolicyOwner: 'Bitte teilen Sie mit uns die die Daten des Versicherungsnehmers mit.'
      },
      placeholders: {
        company: 'Wählen Sie hier Ihre Versicherung.',
        newCompany: 'Bitte den Namen der Versicherungsgesellschaft angeben',
        policyNumber: 'Bitte tragen Sie Ihre Versicherungsnummer ein.',
        policyOwnerFirstName: 'Vorname',
        policyOwnerLastName: 'Nachname',
        policyOwnerEmail: 'E-Mail-Adresse',
        policyOwnerPhone: 'Telefonnummer',
        policyOwnerCity: 'Stadt',
        policyOwnerAddress: 'Adresse'
      },
      errors: {
        company: 'Name der Versicherung wird benötigt',
        policyNumber: 'Versicherungsnummer wird benötigt',
        policyOwnerDetailsRequired: 'Versicherungsnehmersdaten sind benötigt'
      }
    }
  }
};

export default insuranceDetailsTranslations;
