import React from 'react';

const Footer = () => (
  <footer className="app-footer">
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-6 copyright-column">
          <span>Schadenhilfe.online ist ein Angebot der Noxalib GmbH in München</span>
        </div>
        <div className="col-xs-12 col-sm-6 links-parent-container">
          <div className="links-container">
            <a href="/">Über uns</a>
            <a href="/impressum">Impressum</a>
            <a href="/datenschutz">Datenschutz</a>
            <a href="/terms">Allgemeine Geschäftsbedingungen</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
