import React, { Component } from 'react';
import Footer from '../common/footer';
import Header from '../common/header';

class AboutUs extends Component {
  render() {
    return (
      <div>
        <Header />
        <article className="company-about-us">
          <h1 className="company-about-us-title">Über uns</h1>
          <p>
            Als IT-Dienstleister stehen wir für schnelle und professionelle Hilfe im Schadenfall. Über uns können Sie
            jederzeit, bei jeder Versicherung, jeden Schaden melden. Mittels modernster technischen Unterstützung und
            künstlicher Intelligenz werden die Informationen validiert und gebündelt an die zuständigen Stellen
            weitergeleitet. Dabei liefern wir nur eine Datenaufbereitung sowie –weiterleitung, ausdrücklich keine Art
            von Beratung, oder Versicherungsverkauf.
          </p>
          <p>
            Über unser Partnernetzwerk von bundesweit tätigen Versicherungsexperten können wir diesen Service teilweise
            sogar kostenlos anbieten. Gerade im Schadensfall zeigt es sich wie gut eine Versicherung ist. Über unsere
            zertifizierten Partner mit eigener Rechtsabteilung gewährleisten wir die bestmögliche und vor allem
            unabhängige Unterstützung bei der Abwicklung (mit) Ihres Schadensfalles. Über laufende(r) Serviceverträge
            mit allen Versicherungen, werden in diesem Fall eventuell anfallende Gebühren direkt von der Versicherung
            und unseren Partnern bezahlt.
          </p>
        </article>

        <Footer />
      </div>
    );
  }
}

export default AboutUs;
