import accidentTypeTranslations from './accident-type';
import whatWasDamagedTranslations from './what-was-damaged';
import vehiclePrivateUseTranslations from './vehicle-private-use';
import vehicleTypeTranslations from './vehicle-type';
import vehicleRegistrationDriverTranslations from './vehicle-registration-driver';
import accidentCircumstances from './accident-circumstances';
import accidentDateTranslations from './accident-date';
import accidentLocationTranslations from './accident-location';
import damagesToReportTranslations from './damages-to-report';
import currentVehicleStateTranslations from './current-vehicle-state';
import witnessesTranslations from './witnesses';
import policeReportTranslations from './police-report';
import drugTestTranslations from './drug-test';
import carPartsTranslations from './car-parts';
import thirdPartyVehiclesDamageTranslations from './third-party-vehicles-damage';
import thirdPartyPersonsDamageTranslations from './third-party-persons-damage';
import thirdPartyGoodsDamageTranslations from './third-party-goods-damage';
import accidentResponsabilityTranslations from './accident-responsability';
import animalCauseTranslations from './animal-cause';
import animalAccidentCircumstancesTranslations from './animal-accident-circumstances';
import naturalCauseTranslations from './natural-causes';
import naturalCircumstancesTranslations from './natural-circumstances';
import explosionCauseTranslations from './explosion-cause';
import explosionCircumstancesTranslations from './explosion-circumstances';
import theftCauseTranslations from './theft-cause';
import theftPartsTranslations from './theft-parts';
import culpritTranslations from './culprit';
import vandalismCircumstancesTranslations from './vandalism-circumstances';
import suggestDamageSurveyor from './suggest-damage-surveyor';

const carFormTranslations = {
  en: {
    licensePlateValidationError: 'Please type the correct license plate number',
    accidentType: { ...accidentTypeTranslations.en },
    whatWasDamaged: { ...whatWasDamagedTranslations.en },
    vehicleForPrivateUse: { ...vehiclePrivateUseTranslations.en },
    vehicleType: { ...vehicleTypeTranslations.en },
    vehicleRegistrationDriver: { ...vehicleRegistrationDriverTranslations.en },
    accidentCircumstances: { ...accidentCircumstances.en },
    accidentDate: { ...accidentDateTranslations.en },
    accidentLocation: { ...accidentLocationTranslations.en },
    damagesToReport: { ...damagesToReportTranslations.en },
    currentVehicleState: { ...currentVehicleStateTranslations.en },
    witnesses: { ...witnessesTranslations.en },
    policeReport: { ...policeReportTranslations.en },
    drugTest: { ...drugTestTranslations.en },
    carParts: { ...carPartsTranslations.en },
    thirdPartyVehiclesDamage: { ...thirdPartyVehiclesDamageTranslations.en },
    thirdPartyPersonsDamage: { ...thirdPartyPersonsDamageTranslations.en },
    thirdPartyGoodsDamage: { ...thirdPartyGoodsDamageTranslations.en },
    accidentResponsability: { ...accidentResponsabilityTranslations.en },
    animalCause: { ...animalCauseTranslations.en },
    animalAccidentCircumstances: {
      ...animalAccidentCircumstancesTranslations.en
    },
    naturalCause: { ...naturalCauseTranslations.en },
    naturalCircumstances: { ...naturalCircumstancesTranslations.en },
    explosionCause: { ...explosionCauseTranslations.en },
    explosionCircumstances: { ...explosionCircumstancesTranslations.en },
    theftCause: { ...theftCauseTranslations.en },
    theftParts: { ...theftPartsTranslations.en },
    culprit: { ...culpritTranslations.en },
    vandalimsCircumstances: { ...vandalismCircumstancesTranslations.en },
    suggestDamageSurveyor: { ...suggestDamageSurveyor.en }
  },
  de: {
    licensePlateValidationError: 'Bitte das korrekte KFZ-Kennzeichen angeben',
    accidentType: { ...accidentTypeTranslations.de },
    whatWasDamaged: { ...whatWasDamagedTranslations.de },
    vehicleForPrivateUse: { ...vehiclePrivateUseTranslations.de },
    vehicleType: { ...vehicleTypeTranslations.de },
    vehicleRegistrationDriver: { ...vehicleRegistrationDriverTranslations.de },
    accidentCircumstances: { ...accidentCircumstances.de },
    accidentDate: { ...accidentDateTranslations.de },
    accidentLocation: { ...accidentLocationTranslations.de },
    damagesToReport: { ...damagesToReportTranslations.de },
    currentVehicleState: { ...currentVehicleStateTranslations.de },
    witnesses: { ...witnessesTranslations.de },
    policeReport: { ...policeReportTranslations.de },
    drugTest: { ...drugTestTranslations.de },
    carParts: { ...carPartsTranslations.de },
    thirdPartyVehiclesDamage: { ...thirdPartyVehiclesDamageTranslations.de },
    thirdPartyPersonsDamage: { ...thirdPartyPersonsDamageTranslations.de },
    thirdPartyGoodsDamage: { ...thirdPartyGoodsDamageTranslations.de },
    accidentResponsability: { ...accidentResponsabilityTranslations.de },
    animalCause: { ...animalCauseTranslations.de },
    animalAccidentCircumstances: {
      ...animalAccidentCircumstancesTranslations.de
    },
    naturalCause: { ...naturalCauseTranslations.de },
    naturalCircumstances: { ...naturalCircumstancesTranslations.de },
    explosionCause: { ...explosionCauseTranslations.de },
    explosionCircumstances: { ...explosionCircumstancesTranslations.de },
    theftCause: { ...theftCauseTranslations.de },
    theftParts: { ...theftPartsTranslations.de },
    culprit: { ...culpritTranslations.de },
    vandalimsCircumstances: { ...vandalismCircumstancesTranslations.de },
    suggestDamageSurveyor: { ...suggestDamageSurveyor.de }
  }
};

export default carFormTranslations;
