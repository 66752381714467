import { Actions } from '../actions/action-types';

const initialState = {
  showGDPRModal: false,
  hash: ''
};

export default function cookiesReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.SHOW_GDPR_COOKIE_MODAL:
      return {
        ...state,
        showGDPRModal: action.payload,
        hash: action.hash
      };
    default:
      return state;
  }
}
