import React, { Component } from 'react';

class GDPRModal extends Component {
  constructor(props) {
    super(props);
    const isServer = !global.window;
    this.state = {
      shouldShow: !isServer && window.gdprModalVisble
    };
  }

  submit(optIn) {
    if (optIn) {
      window.GDPRPositive();
    } else {
      window.GDPRNegative();
    }
    this.setState({ shouldShow: false });
    window.gdprModalVisble = false;
  }

  render() {
    if (!global.window) {
      return <></>;
    }
    return (
      <div className="gdpr-overlay" style={{ display: this.state.shouldShow ? 'block' : 'none' }}>
        <div className="container" style={{ maxWidth: '700px' }}>
          <div className="gdpr-box">
            <h2>Ihre Privatsphäre liegt uns am Herzen!</h2>
            <p>
              <strong>
                Um Ihnen die Schadenhilfe.online schnell und sicher anzubieten, arbeiten wir mit Drittanbietern
                zusammen.
              </strong>
            </p>
            <p>
              Die Nutzung von Analyse- und Performancecookies von Google Analytics und Google Ads ermöglicht uns, diese
              Seite für Sie zu verbessern. Sie können entscheiden, ob wir diese Coookies für Ihren Besuch aktivieren.
            </p>
            <p>
              Details zum Umgang mit Ihren Daten und unserem Einsatz von Cookies finden Sie in unserer{' '}
              <a href="/datenschutz">Datenschutzrichtlinie</a>.
            </p>
            <div className="gdpr-buttons">
              <button
                onClick={() => {
                  this.submit(false);
                }}
                className="secondary"
              >
                Nur notwendige Cookies
              </button>
              <button
                onClick={() => {
                  this.submit(true);
                }}
              >
                Alle Cookies akzeptieren
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GDPRModal;
