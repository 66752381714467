import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import config from '../config';
import translationsObject from '../translations/index';
import reducers from './reducers';

const configureStore = (context) => {
  // See https://github.com/zalmoxisus/redux-devtools-extension#usage
  const getComposeEnhancers = () => {
    if (typeof window !== 'undefined') {
      return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    }
    return compose;
  };

  const getInitialState = () => {
    if (typeof window !== 'undefined') {
      // Grab the state from a global variable injected into the server-generated HTML
      const preloadedState = window.__PRELOADED_STATE__;
      // Allow the passed state to be garbage-collected
      delete window.__PRELOADED_STATE__;
      return preloadedState;
    }

    if (context !== null) {
      return { ...context };
    }
    return {};
  };

  const store = createStore(reducers, getInitialState(), getComposeEnhancers()(applyMiddleware(thunk)));

  syncTranslationWithStore(store);
  store.dispatch(loadTranslations(translationsObject));
  // Set the language we want
  store.dispatch(setLocale(config.LANGUAGE));

  return store;
};

export default configureStore;
