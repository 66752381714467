import React, { Component } from 'react';
import Footer from '../common/footer';
import Header from '../common/header';

class Contact extends Component {
  render() {
    return (
      <div>
        <Header />

        <article className="legal-company-details">
          <h1 className="legal-company-details-title">Impressum</h1>
          <small className="legal-company-details-source">Angaben gemäß § 5 TMG / § 55 RStV</small>
          <section className="legal-company-details-section">
            <h2 className="legal-company-details-section-heading">Anschrift</h2>
            <p>
              Noxalib GmbH
              <br />
              Zielstattstr. 19
              <br />
              81379 München
            </p>
            <h2 className="legal-company-details-section-heading">Telefon</h2>
            <p className="legal-company-details-phone">
              <a href="tel:+4989416177088">
                +49<span>89</span>
                <span>4161</span>
                <span>77088</span>
              </a>
            </p>
            <h2 className="legal-company-details-section-heading">E-Mail</h2>
            <p>
              <a href="mailto:info@schadenhilfe.online">info@schadenhilfe.online</a>
            </p>
            <h2 className="legal-company-details-section-heading">Geschäftsführer</h2>
            <p>Ulrich Rose, Manfred Tropper</p>
            <h2 className="legal-company-details-section-heading">Handelsregister</h2>
            <p>München, HRB 257341</p>
            <h2 className="legal-company-details-section-heading">Umsatzsteuer-ID</h2>
            <p>DE331164665</p>
          </section>
          <section className="legal-company-details-section">
            <h2 className="legal-company-details-section-heading">
              Verantwortlicher im Sinne des Rundfunkstaatsvertrags
            </h2>
            <p>Ulrich Rose</p>
            <h2 className="legal-company-details-section-heading">Informationen zur Online-Streitbeilegung</h2>
            <p>
              Gemäß der Richtlinie 2013/11/EU richtet die EU-Kommission eine Internetplattform zur Online-Beilegung von
              Streitigkeiten („OS-Plattform“) zwischen Unternehmern und Verbrauchern ein. Diese ist unter folgendem Link
              erreichbar:
              <a href="http://ec.europa.eu/consumers/odr" target="_blank" rel="noreferrer">
                http://ec.europa.eu/consumers/odr
              </a>
            </p>
            <h2 className="legal-company-details-section-heading">Urheberrechtshinweis</h2>
            <p>
              Alle Inhalte dieses Internetangebotes, insbesondere Texte, Fotografien und Grafiken, sind urheberrechtlich
              geschützt (Copyright).
            </p>
          </section>
        </article>

        <Footer />
      </div>
    );
  }
}

export default Contact;
