import carInitialState from './initial-state/car-state';
import houseInitialState from './initial-state/house-state';
import personInitialState from './initial-state/person-state';
import legalInitialState from './initial-state/legal-state';
import othersInitialState from './initial-state/others-state';

const initialState = {
  broker: null,
  insuranceCompanies: [],
  userInfo: null,
  progress: {
    /**
     * formCompletionPercentage -> This takes values from 0 to 100 and represents the perentage of fields completed
     */
    formCompletionPercentage: 0,
    /**
     * formNavigationStage -> This can take values from 0 to 2 and is used by the form navigation top bar
     */
    formNavigationStage: 0,
    /**
     * Next property gets the error message when saving the form returns an error
     */
    serverError: ''
  },
  notification: {
    show: false,
    applyFunction: null,
    cancelFunction: null,
    formName: null,
    editTime: null
  },
  claimForm: {
    damageType: {
      editMode: true,
      selectedValue: '',
      selectedIds: []
    },
    car: { ...carInitialState },
    house: { ...houseInitialState },
    person: { ...personInitialState },
    legal: { ...legalInitialState },
    others: { ...othersInitialState }
  },
  submittedClaimData: null
};

export default initialState;
