import { Actions } from '../actions/action-types';
import initialState from './initial-state';

export default function userInfoReducer(state = initialState.userInfo, action) {
  switch (action.type) {
    case Actions.SAVE_USER_INFO:
      return { coworkerName: state?.coworkerName, ...action.userInfo };
    default:
      return state;
  }
}
