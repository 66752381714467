import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import AboutUs from './components/about-us/index';
// import Affiliates from './components/affiliates';
// import CallbackRequestForm from './components/callback-request/index';
// import ExistingClaimForm from './components/existing-form';
import ScrollToTop from './components/common/scroll-to-top';
import Contact from './components/contact/index';
import DataProtection from './components/data-protection/index';
// import ClaimForm from './components/form/index';
import Home from './components/home/index';
import TermsConditions from './components/terms-conditions/index';
// import SurveyRoute from './components/survey';
// import SurveyPublicRoute from './components/survey-public';

class Routes extends React.Component {
  render() {
    return (
      <div>
        <Switch>
          <ScrollToTop>
            <Route exact path="/" component={Home} />
            <Route path="/uber-uns" component={AboutUs} />
            <Route path="/impressum" component={Contact} />
            <Route path="/datenschutz" component={DataProtection} />
            {/* <Route exact path="/meldung" component={ClaimForm} /> */}
            {/* <Route exact path="/meldung/coworker" render={(props) => <ClaimForm coworker {...props} />} /> */}
            {/* <Route path="/nachfrage" component={ExistingClaimForm} /> */}
            {/* <Route path="/rueckruf" component={CallbackRequestForm} /> */}
            {/* <Route path="/schnellrechner" component={SurveyRoute} /> */}
            {/* <Route path="/umfrage" component={SurveyPublicRoute} /> */}
            <Route path="/terms" component={TermsConditions} />
            {/* <Route path="/affiliates" component={Affiliates} /> */}
          </ScrollToTop>
        </Switch>
      </div>
    );
  }
}

export default withRouter(Routes);
