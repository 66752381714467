import _ from 'lodash';
import { Actions } from '../actions/action-types';
import initialState from './initial-state';
import carInitialState from './initial-state/car-state';
import houseInitialState from './initial-state/house-state';
import personInitialState from './initial-state/person-state';
import legalInitialState from './initial-state/legal-state';
import othersInitialState from './initial-state/others-state';

const removeProgressPropertyFromStateObject = (stateToProcess) => {
  if (stateToProcess.progress !== undefined) {
    return _.omit(stateToProcess, ['progress']);
  }
  return stateToProcess;
};

export default function formReducer(state = initialState.claimForm, action) {
  switch (action.type) {
    case Actions.RESET_FROM: {
      // start with the initial state
      return { ...initialState.claimForm };
    }
    case Actions.SET_DAMAGE_TYPE: {
      // refresh the claim form state
      const cleanState = initialState.claimForm;
      cleanState.car = { ...carInitialState };
      cleanState.house = { ...houseInitialState };
      cleanState.person = { ...personInitialState };
      cleanState.legal = { ...legalInitialState };
      cleanState.others = { ...othersInitialState };
      return { ...cleanState, damageType: { ...action.payload } };
    }
    case Actions.EDIT_DAMAGE_TYPE:
      return {
        ...state,
        damageType: { ...state.damageType, editMode: action.editMode }
      };
    case Actions.CLEAR_DAMAGE_TYPE:
      return { ...state, damageType: initialState.damageType };
    case Actions.SAVE_SECTION: {
      return { ...state, ...action.newState };
    }
    case Actions.CLEAR_NEWER_DATA: {
      const formToUpdate = state[action.payload.formName];
      const finalUpdateObject = {};
      finalUpdateObject[action.payload.formName] = {
        ...formToUpdate,
        ...action.payload.stateToUpdate
      };
      return { ...state, ...finalUpdateObject };
    }
    case Actions.LOGIC_UPDATE_CAR_FORM: {
      const newState = removeProgressPropertyFromStateObject(action.newState);
      return { ...state, car: { ...newState } };
    }
    case Actions.LOGIC_UPDATE_HOUSE_FORM: {
      const newState = removeProgressPropertyFromStateObject(action.newState);
      return { ...state, house: { ...newState } };
    }
    case Actions.LOGIC_UPDATE_PERSON_FORM: {
      const newState = removeProgressPropertyFromStateObject(action.newState);
      return { ...state, person: { ...newState } };
    }
    case Actions.LOGIC_UPDATE_LEGAL_FORM: {
      const newState = removeProgressPropertyFromStateObject(action.newState);
      return { ...state, legal: { ...newState } };
    }
    case Actions.LOGIC_UPDATE_OTHERS_FORM: {
      const newState = removeProgressPropertyFromStateObject(action.newState);
      return { ...state, others: { ...newState } };
    }
    case Actions.SAVE_SECTION_NAME_IN_EDIT:
      return { ...state, sectionNameInEdit: action.sectionName };
    default:
      return state;
  }
}
