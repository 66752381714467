/**
 * This is the first section of "accident with animals"
 */

const explosionCauseTranslations = {
  en: {
    question: 'What is the cause?',
    questionDescription: 'Please select the option which describes best what happened',
    options: {
      fire: 'Fire',
      explosion: 'Explosion',
      shortCircuit: 'Short-circuit'
    }
  },
  de: {
    question: 'Was ist die Ursache?',
    questionDescription: 'Bitte wählen Sie die Option, die ab ehesten zutrifft.',
    options: {
      fire: 'Feuer',
      explosion: 'Explosion',
      shortCircuit: 'Kurzschluß'
    }
  }
};

export default explosionCauseTranslations;
