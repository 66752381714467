const currentVehicleStateTranslations = {
  en: {
    questionVehicleRepaired: 'Is your vehicle repaired?',
    questionDescription: 'Choose what it applies to you',
    questionVehicleDrivable: 'Can your vehicle still drive?',
    selectedAnswerRepaired: 'Vehicle repaired',
    selectedAnswerNotRepaired: 'Vehicle is not repaired',
    selectedAnswerNoRepairedDrivable: 'Vehicle is not repaired but it is drivable',
    selectedAnswerNoRepairedNotDrivable: "Vehicle is not repaired and it isn't drivable"
  },
  de: {
    questionVehicleRepaired: 'Wurde das Fahrzeug bereits repariert?',
    questionDescription: 'Bitte wählen Sie die zutreffende Antwort aus.',
    questionVehicleDrivable: 'Fährt das Fahrzeug noch?',
    selectedAnswerRepaired: 'Fahrzeug repariert',
    selectedAnswerNotRepaired: 'Fahrzeug nicht repariert',
    selectedAnswerNoRepairedDrivable: 'Fahrzeug nicht repariert aber fahrtüchtig',
    selectedAnswerNoRepairedNotDrivable: 'Fahrzeug nicht repariert und nicht fahrtüchtig'
  }
};

export default currentVehicleStateTranslations;
