const propertyOwnerTranslations = {
  en: {
    question: 'Do you own the damaged item?',
    questionDescription: "If you don't own the damaged item, please give us the details of the owner",
    currentUserOwnsItem: 'I own the damaged item',
    ownerDetailsPrefix: 'Owner details',
    form: {
      placeholders: {
        firstName: "Owner's first name",
        lastName: "Owner's last name",
        phone: "Owner's phone number",
        email: "Owner's email",
        address: "Owner's address",
        company: "Owner's company"
      }
    }
  },
  de: {
    question: 'Sind Sie Eigentümer des beschädigten Gegenstands?',
    questionDescription: 'Wenn Sie nicht Eigentümer sind, bitte teilen Sie uns die Daten des Eigentümers mit.',
    currentUserOwnsItem: 'Ich bin Eigentümer des beschädigten Gegenstands.',
    ownerDetailsPrefix: 'Daten des Eigentümers',
    form: {
      placeholders: {
        firstName: 'Vorname',
        lastName: 'Nachname',
        phone: 'Telefonnummer',
        email: 'E-Mail-Adresse',
        address: 'Adresse',
        company: 'Firma des Eigentümers'
      }
    }
  }
};
export default propertyOwnerTranslations;
