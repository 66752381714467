const thirdPartyGoodsDamageTranslations = {
  en: {
    question: 'What goods were damaged?',
    questionDescription: 'Please describe all the goods which were damaged',
    placeholder: 'Details of the damaged goods'
  },
  de: {
    question: 'Welche Sachen wurden beschädigt?',
    questionDescription: 'Bitte alle beschädigten Güter angeben',
    placeholder: 'Genauere Angaben zu beschädigten Gütern'
  }
};

export default thirdPartyGoodsDamageTranslations;
