const accidentDateTranslations = {
  en: {
    question: 'When did the accident occured?',
    questionDescription: 'Select the date and time'
  },
  de: {
    question: 'Wann ist der Schaden entstanden?',
    questionDescription: 'Bitte wählen Sie Datum und Uhrzeit aus.'
  }
};

export default accidentDateTranslations;
