const thirdPartyPersonsDamageTranslations = {
  en: {
    options: {
      driver: 'Driver',
      passenger: 'Passenger',
      biker: 'Biker',
      pedestrian: 'Pedestrian'
    },
    question: 'What injuries happened to third party persons?',
    questionDescription: 'Give us more information about the persons involved',
    questionSafetyMechanisms: 'Was the person wearing a helmet or belt?',
    questionContactDetails: 'Do you have the contact details of this third party person?',
    questionDamageDetails: 'Do you want to describe the injuries of this person?',
    wearingSafetyMechanism: 'person was wearing safety mechanisms',
    notWearingSafetyMechanism: 'person was not wearing safety mechanisms',
    selectedPersonLabel: 'Person',
    form: {
      labels: {
        damageDescription: 'Injuries details'
      },
      placeholders: {
        damage: "Describe person's injuries"
      },
      addPerson: 'Add person'
    }
  },
  de: {
    options: {
      driver: 'Fahrer',
      passenger: 'Beifahrer',
      biker: 'Radfahrer',
      pedestrian: 'Fußgänger'
    },
    question: 'Welche Verletzungen sind der anderen Person entstanden?',
    questionDescription: 'Bitte genauere Angaben zu den betroffenen Personen machen',
    questionSafetyMechanisms: 'Trug die Person einen Helm oder Sicherheitsgurt?',
    questionContactDetails: 'Sind die Kontaktdaten der Person bekannt?',
    questionDamageDetails: 'Können die Verletzungen beschrieben werden?',
    wearingSafetyMechanism: 'Person trug Helm oder Gurt',
    notWearingSafetyMechanism: 'Person trug keinen Helm oder Gurt',
    selectedPersonLabel: 'Person',
    form: {
      labels: {
        damageDescription: 'Angaben zu Verletzungen'
      },
      placeholders: {
        damage: 'Bitte die Verletzungen der Person beschreiben'
      },
      addPerson: 'Person hinzufügen'
    }
  }
};

export default thirdPartyPersonsDamageTranslations;
