import legalDomainTranslations from './legal-domain';
import disputeDescriptionTranslations from './dispute-description';
import lawyerNeededTranslations from './lawyer-needed';

const legalFormTranslations = {
  en: {
    legalDomain: { ...legalDomainTranslations.en },
    disputeDescription: { ...disputeDescriptionTranslations.en },
    lawyerNeeded: { ...lawyerNeededTranslations.en }
  },
  de: {
    legalDomain: { ...legalDomainTranslations.de },
    disputeDescription: { ...disputeDescriptionTranslations.de },
    lawyerNeeded: { ...lawyerNeededTranslations.de }
  }
};

export default legalFormTranslations;
