/**
 * This is the first section of "person damage"
 */

const damageCauseTranslations = {
  en: {
    question: 'What kind of accident was it?',
    questionDescription: 'Please select the option which describes best what happened',
    options: {
      criminalAggression: 'Damages due to a criminal aggression',
      thirdPartyInvolved: 'Damages due to an accident involving a third party',
      thirdPartyNotInvolved: 'Damages due to an accident not involving a third party'
    }
  },
  de: {
    question: 'Welche Art Unfall ist vorgefallen?',
    questionDescription: 'Bitte die am ehesten zutreffende Option auswählen',
    options: {
      criminalAggression: 'Schaden durch einen kriminellen Angriff',
      thirdPartyInvolved: 'Schaden durch einen Unfall mit anderen Personen',
      thirdPartyNotInvolved: 'Schaden durch einen Unfall ohne andere Personen'
    }
  }
};

export default damageCauseTranslations;
