function getConfig() {
  const isServer = !global.window;
  if (isServer) {
    require('dotenv').config();
    return {
      LANGUAGE: process.env.LANGUAGE,
      BASE_HOSTNAME: process.env.BASE_HOSTNAME,
      API_BASEURL: process.env.API_BASEURL,
      WEFOX_URL: process.env.WEFOX_URL,
      SIMPLECHECK_URL: process.env.SIMPLECHECK_URL,
      USE_SIMPLECHECK: process.env.USE_SIMPLECHECK
    };
  } else {
    return global.window.schadenhilfeConfig;
  }
}

export const configSecret = {
  API_BASEURL_INTERNAL: process.env.API_BASEURL_INTERNAL
};

const config = getConfig();

export default config;
