export const Actions = {
  SAVE_USER_INFO: 'save_user_info',
  SET_PROGRESS_PERCENTAGE: 'set_progress_percentage',
  SET_PROGRESS_STAGE: 'set_progress_stage',
  SET_DAMAGE_TYPE: 'set_damage_type',
  CLEAR_DAMAGE_TYPE: 'clear_damage_type',
  EDIT_DAMAGE_TYPE: 'edit_damage_type',
  SAVE_SECTION: 'save_section',
  SHOW_NOTIFICATION_MODAL: 'show_notification_modal',
  HIDE_NOTIFICATION_MODAL: 'hide_notification_modal',
  CLEAR_NEWER_DATA: 'clear_newer_data',
  LOGIC_UPDATE_CAR_FORM: 'logic_update_car_form',
  LOGIC_UPDATE_HOUSE_FORM: 'logic_update_house_form',
  LOGIC_UPDATE_PERSON_FORM: 'logic_update_person_form',
  LOGIC_UPDATE_LEGAL_FORM: 'logic_update_legal_form',
  LOGIC_UPDATE_OTHERS_FORM: 'logic_update_others_form',
  SAVE_SECTION_NAME_IN_EDIT: 'save_section_name_in_edit',
  SHOW_ERROR_MODAL: 'show_error_modal',
  HIDE_ERROR_MODAL: 'hide_error_modal',
  RESET_FROM: 'reset_from',
  SAVE_CLAIM_DATA: 'save_cliam_data',
  SAVE_PAID_OPTION: 'save_paid_option',
  CLEAR_HELLOSIGN_INFO: 'clear_hellosign_info',
  SAVE_WEFOX_VERSION: 'save_wefox_version',
  SHOW_GDPR_COOKIE_MODAL: 'SHOW_GDPR_COOKIE_MODAL'
};
