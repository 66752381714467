const summaryStepTranslations = {
  en: {
    headerTitle: 'Claim summary',
    title: 'Below you can find the summary of the form',
    mainSummary:
      'You {{accidentUser}}, are making a {{accidentType}} claim for something that happened on {{accidentDate}} for the insurance policy holder {{insurancePolicyHolder}} who has a contract with {{insuranceCompanyName}} with the insurance policy number: {{insurancePolicyNumber}}.',
    damagedPersons: 'The following persons were damaged',
    damageDescription: 'You described the damages like this',
    damageEstimate: 'This is the damage estimate',
    continueButton: 'Yes, submit claim now.',
    backButton: 'Go Back',
    accidentCircumstances: 'Circumstances of the loss:'
  },
  de: {
    headerTitle: 'Zusammenfassung',
    title: 'Haben wir alles richtig verstanden?',
    mainSummary:
      'Sie, {{accidentUser}}, melden einen {{accidentType}}-Schaden am Schadentag {{accidentDate}} für den Versicherungsvertrag von {{insurancePolicyHolder}} bei der {{insuranceCompanyName}} mit Vertragsnummer: {{insurancePolicyNumber}}.',
    damagedPersons: 'Geschädigter ist',
    damageDescription: 'Sie schildern den Schaden so:',
    damageEstimate: 'Ungefähre Schadenhöhe',
    continueButton: 'Ja, Schadenmeldung jetzt absenden.',
    backButton: 'Zurück',
    accidentCircumstances: 'Schadenhergang:'
  }
};

export default summaryStepTranslations;
