import React from 'react';
import SVGInline from 'react-svg-inline';
import legalIcon from '../../public/assets/icons/legal.svg';
import editIcon from '../../public/assets/icons/edit.svg';
import checkmarkIcon from '../../public/assets/icons/checkmark.svg';

const ProcessDescription = () => (
  <div className="container">
    <div className="row process-description">
      <div className="col-12" style={{ textAlign: 'center' }}>
        <h2>Egal welche Versicherungsgesellschaft. Egal was passiert ist:</h2>
        <h2>Wir sind Ihr Schadenmanager, von der Meldung bis zur Reparatur, schnell und unkompliziert.</h2>
      </div>
      <div className="col-12 sub-title">
        <h3>So geht's:</h3>
      </div>
      <div className="col-12 process-steps">
        <div className="process-item">
          <div className="process-count">1</div>
          <div className="title">Sie füllen unser smartes Eingabeformular aus</div>
          <div className="process-image">
            <SVGInline svg={editIcon} />
          </div>
          <div className="process-footer">8 Minuten für eine komplette Meldung.</div>
        </div>

        <div className="process-item">
          <div className="process-count">2</div>
          <div className="title">Wir prüfen Ihren Fall und schicken ihn an Ihre Versicherung</div>
          <div className="process-image">
            <SVGInline svg={legalIcon} />
          </div>
          <div className="process-footer">Wir kümmern uns um die gesamte Abwicklung.</div>
        </div>

        <div className="process-item">
          <div className="process-count">3</div>
          <div className="title">Sie erhalten Ihre Entschädigung schnell und sicher</div>
          <div className="process-image">
            <SVGInline svg={checkmarkIcon} />
          </div>
          <div className="process-footer">Keine nervigen Nachfragen Ihrer Versicherung.</div>
        </div>
      </div>
      <div className="col-12" style={{ textAlign: 'center' }}>
        <h3>Und das Beste: dieser Service ist für Sie 100% kostenlos.</h3>
      </div>
    </div>
  </div>
);

export default ProcessDescription;
