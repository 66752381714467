/**
 * This is shared by "theft culprit" and "vandalism culprit"
 */
const culpritTranslations = {
  en: {
    question: 'Do you know who the culprit is?',
    questionDescription: 'If you know the culprit please provide all the contact details you have',
    culpritUnknown: 'Culprit unknown',
    form: {
      placeholders: {
        firstName: 'Culprit first name',
        lastName: 'Culprit last name',
        phone: 'Type the phone number of the culprit',
        email: 'Email of the culprit',
        address: 'Culprit address'
      }
    }
  },
  de: {
    question: 'Ist der Täter bekannt?',
    questionDescription: 'Bitte genauere Angaben machen falls bekannt',
    culpritUnknown: 'Täter unbekannt',
    form: {
      placeholders: {
        firstName: 'Vorname des Täters',
        lastName: 'Nachname des Täters',
        phone: 'Telefonnummer des Täters',
        email: 'Email-Adresse des Täters',
        address: 'Adresse des Täters'
      }
    }
  }
};
export default culpritTranslations;
