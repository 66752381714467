const thirdPartyVehiclesDamageTranslations = {
  en: {
    question: 'What are the damaged third party vehicles?',
    questionDescription: 'Give us more information about the other vehicles involved in accident',
    questionContactDetails: 'Do you have the contact details of this third party vehicle?',
    questionDamageDetails: 'Do you want to describe the damages of this third party vehicle?',
    selectedVehicleLabel: 'Vehicle',
    form: {
      labels: {
        damageDescription: 'Damages details for vehicle'
      },
      placeholders: {
        firstName: 'First name',
        lastName: 'Last name',
        phone: 'Phone',
        email: 'Email',
        address: 'Address',
        damage: 'Describe damage'
      },
      errorMessage: {
        firstNameRequired: 'First name is required',
        lastNameRequired: 'Last name is required'
      },
      removeItem: 'Remove',
      editItem: 'Edit',
      invalidLicensePlate: 'License plate number is invalid',
      addVehicle: 'Add vehicle',
      saveButton: 'Save information'
    }
  },
  de: {
    question: 'Welche anderen Fahrzeuge wurden beschädigt?',
    questionDescription: 'Bitte Informationen zu Fahrzeug der Unfallbeteiligten',
    questionContactDetails: 'Sind Kontaktdetails des anderen Fahrzeughalters bekannt?',
    questionDamageDetails: 'Können genauere Angaben zu den Schäden am anderen Fahrzeug gemacht werden?',
    selectedVehicleLabel: 'Fahrzeug',
    form: {
      labels: {
        damageDescription: 'Schäden am anderen Fahrzeug'
      },
      placeholders: {
        firstName: 'Vorname',
        lastName: 'Nachname',
        phone: 'Telefonnummer',
        email: 'Email-Adresse',
        address: 'Adresse',
        damage: 'Schadensbeschreibung'
      },
      errorMessage: {
        firstNameRequired: 'Vorname wird benötigt',
        lastNameRequired: 'Nachname wird benötigt'
      },
      removeItem: 'Entfernen',
      editItem: 'Ändern',
      invalidLicensePlate: 'Autokennzeichen ungültig',
      addVehicle: 'Fahrzeug hinzufügen',
      saveButton: 'Angaben speichern'
    }
  }
};

export default thirdPartyVehiclesDamageTranslations;
