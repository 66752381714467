const theftPartsTranslations = {
  en: {
    question: 'What parts were stolen?',
    questionDescription: 'Please list all the stolen parts',
    placeholder: 'Details of stolen parts'
  },
  de: {
    question: 'Welche Fahrzeugteile wurden gestohlen?',
    questionDescription: 'Bitte alle Teile angeben',
    placeholder: 'Genauere Angaben zu gestohlenen Fahrzeugteilen'
  }
};

export default theftPartsTranslations;
