const accidentCircumstancesTranslations = {
  en: {
    question: 'How did the damage happen?',
    questionDescription: 'Select one of the following accident circumstances',
    options: {
      collision: 'Collision',
      parking: 'Parking',
      priotityViolated: 'Not yielding (right before left)',
      turn: 'In a turn',
      roadConditions: 'Because of the road damage',
      overtaking: 'Overtaking maneuver',
      laneChange: 'Lane change',
      other: 'Other circumstances'
    }
  },
  de: {
    question: 'Wie ist der Schaden entstanden?',
    questionDescription:
      'Bitte wählen Sie den am besten passenden Fall aus. Sie können später detaillierte Angaben machen.',
    options: {
      collision: 'Kollision',
      parking: 'Parken',
      priotityViolated: 'Vorfahrt missachtet',
      turn: 'Beim Abbiegen',
      roadConditions: 'Durch Straßenschäden',
      overtaking: 'Durch ein Überholmanöver',
      laneChange: 'Beim Spurwechsel',
      other: 'Sonstiges'
    }
  }
};

export default accidentCircumstancesTranslations;
