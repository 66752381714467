const explosionCircumstancesTranslations = {
  en: {
    question: 'How did you vehicle caught fire?',
    questionDescription: 'Please describe the accident',
    placeholder: 'Details of what happened'
  },
  de: {
    question: 'Wie hat das Fahrzeug Feuer gefangen?',
    questionDescription: 'Bitte den Unfall beschreiben',
    placeholder: 'Detailangaben zum Unfallverlauf'
  }
};

export default explosionCircumstancesTranslations;
