const drugTestTranslations = {
  en: {
    questionDrugTestTaken: 'Was there a drug test?',
    questionDescription: 'Choose what it applies to you',
    questionDrugTestPositive: 'Was the test positive?',
    selectedAnswerTestNotTaken: 'No drug test was taken',
    selectedAnswerNegative: 'Drug test was taken and the result was negative',
    selectedAnswerPositive: 'Drug test was taken and the result was positive'
  },
  de: {
    questionDrugTestTaken: 'Wurde ein Drogentest durchgeführt?',
    questionDescription: 'Bitte alle zutreffenden Angaben auswählen',
    questionDrugTestPositive: 'War der Test positiv?',
    selectedAnswerTestNotTaken: 'Kein Test vorgenommen',
    selectedAnswerNegative: 'Drogentest durchgeführt mit negativem Ergebnis',
    selectedAnswerPositive: 'Drogentest durchgeführt mit positivem Ergebnis'
  }
};

export default drugTestTranslations;
